import { useInfrastructureApiClient } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { CoordinatesField } from "../../ui/GenericFields";
import { SearchInput } from "../../ui/Inputs";
import { useMemo, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import {
    InfraTypeFilter,
    LocationFilterV2,
    ParentFilter,
} from "../DataTable/TableFilters";
import { configurableColumns } from "./constants";
import { TableSettingsModal } from "../DataTable/Extra";
import { DateTime } from "luxon";
import {
    DataPointMapLocation,
    Infrastructure,
} from "../../apiClient/generated";
import { DataTableV3, RowActionButtons } from "../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../DataTable/state";
import { SecondaryButton } from "../../ui/Buttons";
import { useResetAtom } from "jotai/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/sharp-light-svg-icons";

const columnHelper = createColumnHelper<Infrastructure>();

export const InfrastructureApiDataTable = () => {
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Table state
    const tableAtom = useMemo(
        () =>
            tableStateFamily({
                tableId: "infrastructure",
                initialState: {
                    columnVisibility: {
                        infraStatus: false,
                        pipelineDiameter: false,
                        pipelineCommodity: false,
                        pipelineHasPhmsa: false,
                        pipelineSegmentType: false,
                        dateOfInstallation: false,
                    },
                },
            }),
        [],
    );
    const [tableState, setTableState] = useAtom(tableAtom);
    const resetTableState = useResetAtom(tableAtom);

    const fetchFunction = async (props: any) => {
        return await apiClient.infrastructureListList(props);
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                id: "name",
                cell: (info) => (
                    <div className="overflow-hidden truncate">
                        {info.getValue()}
                    </div>
                ),
                header: () => <span>Name</span>,
                size: 200,
            }),
            columnHelper.accessor("siteName", {
                id: "siteName",
                cell: (info) => info.getValue(),
                header: () => <span>Site Name</span>,
                size: 200,
            }),
            columnHelper.accessor("infraStatus", {
                id: "infraStatus",
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <span className="capitalize">
                            {value.replaceAll("_", " ").toLowerCase()}
                        </span>
                    );
                },
                header: () => <span>Status</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineDiameter", {
                id: "pipelineDiameter",
                cell: (info) => info.getValue(),
                header: () => <span>Diameter (inches)</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("dateOfInstallation", {
                id: "dateOfInstallation",
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        value &&
                        DateTime.fromJSDate(value).toLocaleString(
                            DateTime.DATE_SHORT,
                        )
                    );
                },
                header: () => <span>Installation Date</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineCommodity", {
                id: "pipelineCommodity",
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <span className="capitalize">
                            {value?.replaceAll("_", " ").toLowerCase()}
                        </span>
                    );
                },
                header: () => <span>Commodity</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineHasPhmsa", {
                id: "pipelineHasPhmsa",
                cell: (info) => (info.getValue() ? "Yes" : "No"),
                header: () => <span>Pipeline PHMSA</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("pipelineSegmentType", {
                id: "pipelineSegmentType",
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <span className="capitalize">
                            {value?.replaceAll("_", " ").toLowerCase()}
                        </span>
                    );
                },
                header: () => <span>Segment Type</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor((row) => row.ownerName, {
                id: "owner",
                cell: (info) => <i>{info.getValue()}</i>,
                header: () => "Owner",
                size: 120,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("infraType", {
                id: "infraType",
                header: () => "Infrastructure",
                cell: (info) => (
                    <span className="capitalize">
                        {info.renderValue()?.replaceAll("_", " ").toLowerCase()}
                    </span>
                ),
                size: 100,
                meta: {
                    customFilterWidget: InfraTypeFilter,
                },
            }),
            columnHelper.accessor("equipmentType", {
                id: "equipmentType",
                header: "Equipment Type",
                cell: (info) => {
                    const equipmentType = info.getValue();
                    if (equipmentType) {
                        return equipmentType;
                    } else {
                        return "-";
                    }
                },
                size: 190,
            }),
            columnHelper.accessor("location", {
                id: "location",
                header: () => "Location",
                cell: (info) => {
                    const value = info.getValue();
                    if (value) {
                        return (
                            <CoordinatesField
                                coordinates={value.coordinates}
                                allowCopy={true}
                            />
                        );
                    }
                    return "-";
                },
                size: 160,
                meta: {
                    customFilterWidget: LocationFilterV2,
                },
                enableSorting: false,
            }),
            columnHelper.accessor((row) => row.parentName, {
                id: "parent",
                header: () => "Parent",
                meta: {
                    customFilterWidget: ParentFilter,
                },
                enableSorting: false,
            }),
            columnHelper.display({
                id: "actions",
                header: (props) => (
                    <div className="w-full flex justify-end">
                        <button
                            onClick={() => {
                                props.table.resetColumnFilters();
                            }}
                        >
                            Clear filters
                        </button>
                    </div>
                ),
                cell: (props) => {
                    // TODO: Implement a proper API to launch map
                    const point = props.row.getValue("location") as
                        | DataPointMapLocation
                        | undefined;
                    if (!point) {
                        return null;
                    }

                    return (
                        <RowActionButtons
                            actions={[
                                {
                                    fn: () => {
                                        props.table.setColumnFilters((old) => {
                                            return [
                                                ...old,
                                                {
                                                    id: "parent",
                                                    value: props.row.original
                                                        .id,
                                                },
                                            ];
                                        });
                                    },
                                    icon: <FunnelIcon className="w-4 h-4" />,
                                    tooltip: "Filter using this as parent",
                                },
                            ]}
                        />
                    );
                },
            }),
        ],
        [],
    );

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    placeholder="Search by name, type, facility..."
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                    className="w-96"
                />
                <div className="flex-grow" />
                <div className="flex gap-2 items-center">
                    <SecondaryButton variant="sm" onClick={resetTableState}>
                        <FontAwesomeIcon
                            icon={faFilterCircleXmark}
                            className="w-4"
                        />
                        Clear filters
                    </SecondaryButton>
                    <TableSettingsModal
                        columnVisibility={tableState.columnVisibility}
                        setColumnVisibility={(newStates) => {
                            setTableState((ts) => {
                                return {
                                    ...ts,
                                    columnVisibility: newStates,
                                };
                            });
                        }}
                        configurableColumns={configurableColumns}
                    />
                </div>
            </div>

            {/* Data table */}
            <DataTableV3<Infrastructure>
                dataName="infrastructure"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={true}
            />
        </div>
    );
};
