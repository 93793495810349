import { SimpleDataPoint } from "../../apiClient/generated";
import { DateTime } from "luxon";
import { AlignedDataDisplay, CoordinatesField } from "../../ui/GenericFields";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { UnifiedIcon } from "../UnifiedIcon";
import { faChevronDown, faChevronUp } from "@fortawesome/sharp-light-svg-icons";
import lodash from "lodash";

interface DataPointDisplayProps {
    dataPoint?: SimpleDataPoint;
}

export const DataPointDisplay = (props: DataPointDisplayProps) => {
    const [showExtraData, setShowExtraData] = useState(false);
    const viewData = [
        {
            label: "Provider name:",
            value: (
                <>
                    {props.dataPoint.providerName}
                    <Link
                        className="ml-1 text-xs underline"
                        to={`${ROUTES.LICENSING}?section_id=${props.dataPoint.providerName}`}
                        target="_blank"
                    >
                        (License)
                    </Link>
                </>
            ),
        },
        props.dataPoint.secondaryDataSource
            ? {
                  label: "Secondary data source:",
                  value: props.dataPoint.secondaryDataSource || "-",
              }
            : undefined,
        props.dataPoint.data?.quantification_institution
            ? {
                  label: "Quantifying institution:",
                  value: props.dataPoint.data?.quantification_institution,
              }
            : undefined,
        {
            label: "Coordinates:",
            value: (
                <CoordinatesField
                    coordinates={props.dataPoint.location.coordinates}
                    allowCopy={true}
                />
            ),
        },
        {
            label: "Time (UTC):",
            value: DateTime.fromJSDate(
                props.dataPoint.detectionTimestamp,
            ).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
        },
    ].filter((x) => x) as { label: string; value: any }[];

    const extraData = Object.keys(props.dataPoint.data || []).map(
        (key: string) => {
            return {
                label: `${lodash.capitalize(key).replaceAll("_", " ")}:`,
                value: props.dataPoint.data[key],
            };
        },
    );

    if (showExtraData) {
        viewData.push(...[{ label: "---", value: "---" }, ...extraData]);
    }

    if (
        props.dataPoint.detectedRateUncertainty &&
        props.dataPoint.detectedRateUncertainty > 0
    ) {
        viewData.unshift({
            label: "Detected rate uncertainty:",
            value: (
                <>
                    ±{" "}
                    {(props.dataPoint.detectedRateUncertainty / 1000).toFixed(
                        2,
                    )}{" "}
                    kg/h
                </>
            ),
        });
    }

    if (props.dataPoint.detectedRate && props.dataPoint.detectedRate > 0) {
        viewData.unshift({
            label: "Detected rate:",
            value: <>{(props.dataPoint.detectedRate / 1000).toFixed(2)} kg/h</>,
        });
    }

    if (props.dataPoint.concentrationUncertainty) {
        viewData.unshift({
            label: "Concentration uncertainty:",
            value: (
                <>
                    ± {props.dataPoint.concentrationUncertainty.toFixed(2)}{" "}
                    ppm*m
                </>
            ),
        });
    }

    if (props.dataPoint.concentration) {
        viewData.unshift({
            label: "Concentration:",
            value: <>{props.dataPoint.concentration.toFixed(2)} ppm*m</>,
        });
    }

    return (
        <>
            <AlignedDataDisplay data={viewData} />
            {extraData.length > 0 && (
                <button
                    className="mt-2 ml-1 text-xs underline"
                    onClick={() => setShowExtraData(!showExtraData)}
                    type="button"
                >
                    Show {showExtraData ? "less" : "more"}
                    <UnifiedIcon
                        icon={showExtraData ? faChevronUp : faChevronDown}
                        className="h-3 w-3 text-black pl-1"
                    />
                </button>
            )}
        </>
    );
};
