// Backend URL configuration
export const BACKEND_URL =
    import.meta.env.VITE_BACKEND_URL || "http://localhost:8000";
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN || "localhost";

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV || "frontend";
export const SUPPORT_EMAIL =
    import.meta.env.VITE_SUPPORT_EMAIL || "aerscape-help@aerscape.com";

// File extension whitelist
// Used to limit the types of files that users can upload to the platform.
export const ALLOWED_FILE_EXTENSIONS = [
    // Data files
    ".csv",
    ".xlsx",
    ".xls",
    ".json",
    ".txt",
    ".pdf",
    // GeoSpatial formats
    ".kml",
    ".kmz",
    ".shp",
    ".shx",
    ".dbf",
    ".prj",
    ".xml",
    ".cpg",
    ".geojson",
    // Rasters
    ".tiff",
    ".tif",
    ".geotif",
    // ZIPs,
    ".zip",
    // Videos
    ".mp4",
];
