import { RasterSourceSpecification } from "maplibre-gl";

export const basemaps: {
    [key: string]: {
        mapData: RasterSourceSpecification;
        displayName: string;
    };
} = {
    esri: {
        displayName: "ESRI",
        mapData: {
            type: "raster",
            tiles: [
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            ],
            tileSize: 256,
            attribution: "Map tiles by ESRI",
        },
    },
    usgs: {
        displayName: "US Geological Survey",
        mapData: {
            type: "raster",
            tiles: [
                "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
            ],
            tileSize: 256,
            attribution: "Tiles courtesy of the U.S. Geological Survey",
        },
    },
    osm: {
        displayName: "Open Street Map",
        mapData: {
            type: "raster",
            tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution: "OpenStreetMap contributors",
        },
    },
};

export type AvailableBasemaps = keyof typeof basemaps;
export type BasemapList = keyof typeof basemaps;
