import { ControlGroup } from "../../ui/MapControls";
import { DrawOnMapControl } from "./DrawOnMapControl";
import { GoToLocationControl } from "./GoToLocationControl";
import { BasemapControls } from "./BasemapControls";
import { ShareMapControl } from "./ShareMapControl";
import { ControlContainer } from "../common";
import { useRef } from "react";
import { PlumeOpacityControl } from "./PlumeOpacityControl";
import { MapSettingsControl } from "./MapSettingsControl";
import { DateRangeControl } from "./DateRangeControl";

export const DataControls = () => {
    const controlContainerRef = useRef();

    return (
        <ControlContainer className="flex-col p-1 absolute top-2 left-1/2 transform -translate-x-1/2">
            <div className="flex gap-2">
                <DateRangeControl />
                <MapSettingsControl />
                <ControlGroup>
                    <GoToLocationControl />
                    <DrawOnMapControl />
                    <BasemapControls />
                    <ShareMapControl />
                </ControlGroup>
                <PlumeOpacityControl containerRef={controlContainerRef} />
            </div>
            <div ref={controlContainerRef} className="w-full"></div>
        </ControlContainer>
    );
};
