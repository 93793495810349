import { InfrastructureApiDataTable } from "../components/InfrastructureDataTable/InfrastructureDataTable";
import { PermissionGate } from "./PermissionGate";
import { DashboardContainer, DashboardHeader } from "../ui/DashboardComponents";
import { useAppSelector } from "../hooks";
import { useNavigate } from "react-router";
import { ROUTES } from ".";
import { BarsArrowUpIcon } from "@heroicons/react/24/outline";

export const InfrastructurePage = () => {
    const navigate = useNavigate();
    const canViewInfrastructureImport = useAppSelector(
        (state) => state.auth.permissions.infrastructureBuilderView,
    );
    const actions = [];

    if (canViewInfrastructureImport) {
        actions.push({
            label: (
                <span className="flex items-center gap-2">
                    <BarsArrowUpIcon className="w-5 h-5" />
                    Manage Infrastructure
                </span>
            ),
            fn: () => navigate(ROUTES.INFRASTRUCURE_IMPORT),
        });
    }

    return (
        <PermissionGate permission="infrastructureView">
            <DashboardContainer>
                <DashboardHeader title="Infrastructure" actions={actions} />
                <InfrastructureApiDataTable />
            </DashboardContainer>
        </PermissionGate>
    );
};
