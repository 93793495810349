import { useMemo, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { ControlButton } from "../ui/MapControls";
import { ControlContainer } from "../elements/common";
import DeckGL from "@deck.gl/react";
import Map from "react-map-gl/maplibre";
import {
    CROSSHAIR_ICONS_NO_CONTRAST,
    EMISSION_BINS,
    MAP_ZOOM_SHOW_DETAILS,
} from "../constants";
import * as turf from "@turf/turf";
import { useMap } from "../hooks/mapState";
import {
    faCircleInfo,
    faPlus,
    faMinus,
    faMapLocation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { basemaps } from "../../Map/basemaps";

type ExtraContext = "MINIMAP" | "LEGEND";

const EmissionItemLegend = (props: { label: string; icon: any }) => (
    <li className="mb-2 flex gap-4 items-center text-sm">
        <img src={props.icon} className="h-5" />
        {props.label}
    </li>
);

const Legend = () => {
    const { debounced } = useMap("mainMap");
    return (
        <div className="rounded p-2 bg-white min-h-36 text-sm w-60">
            <div className="px-1 text-sm font-semibold mb-4">
                Emissions Legend
            </div>
            {debounced.viewState.zoom < MAP_ZOOM_SHOW_DETAILS && (
                <div className="mb-6 px-4 flex items-start justify-between gap-2">
                    {EMISSION_BINS.map((bin) => (
                        <div className="flex flex-col justify-center items-center">
                            <div className="h-12 flex justify-center items-center">
                                <div
                                    className={`
                                        border-2 rounded-full
                                        bg-gray-300 border-ae-blue-900
                                    `}
                                    style={{
                                        width: `${bin.size}px`,
                                        height: `${bin.size}px`,
                                    }}
                                    title={bin.label}
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                {bin.label}
                            </div>
                            <div className="flex items-center justify-center">
                                kg/h
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <ul className="px-2">
                <EmissionItemLegend
                    label="Third Party"
                    icon={CROSSHAIR_ICONS_NO_CONTRAST.thirdParty}
                />
                <EmissionItemLegend
                    label="My Company Monitoring"
                    icon={CROSSHAIR_ICONS_NO_CONTRAST.operatorProvided}
                />
                <EmissionItemLegend
                    label="EPA Super Emitter Program"
                    icon={CROSSHAIR_ICONS_NO_CONTRAST.epa}
                />
            </ul>
        </div>
    );
};

const MiniMap = () => {
    const { debounced } = useMap("mainMap");
    const layer = useMemo(() => {
        const worldPolygon = turf.bboxPolygon([-180, -90, 180, 90]);
        return new GeoJsonLayer({
            id: "infrastructure",
            data: turf.difference(worldPolygon, debounced.areaOnScreen),
            pointType: "circle",
            filled: true,
            getPointRadius: 6,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            getFillColor: [255, 255, 255, 30],
            getLineWidth: 2,
            getLineColor: [0, 0, 0, 255],
        });
    }, [debounced.areaOnScreen]);

    return (
        <div className="relative h-44 w-60 rounded overflow-hidden">
            <DeckGL
                viewState={
                    {
                        ...debounced.viewState,
                        zoom: Math.max(1, debounced.viewState.zoom - 6),
                    } as any
                }
                controller={false}
                layers={[layer]}
            >
                <Map
                    mapStyle={{
                        version: 8,
                        sources: {
                            "basemap-tiles": basemaps.esri.mapData,
                        },
                        layers: [
                            {
                                id: "basemap-tiles",
                                type: "raster",
                                source: "basemap-tiles",
                            },
                        ],
                    }}
                    attributionControl={false}
                />
            </DeckGL>
        </div>
    );
};

/**
 * ZoomAndLegend
 *
 * FIXME: currently, this component is only usable in the map map.
 */
export const ZoomAndLegend = () => {
    const { zoomIn, zoomOut } = useMap("mainMap");
    const [showExtra, setShowExtra] = useState<ExtraContext>();

    const toggle = (controlName: ExtraContext) => {
        if (controlName === showExtra) {
            setShowExtra(undefined);
        } else {
            setShowExtra(controlName);
        }
    };

    return (
        <ControlContainer className="gap-2 p-1 items-end">
            {showExtra === "MINIMAP" && <MiniMap />}
            {showExtra === "LEGEND" && <Legend />}
            <div className="flex flex-col gap-3">
                <div className="flex flex-col rounded divide-x">
                    <ControlButton className="w-8 rounded-t" onClick={zoomIn}>
                        <FontAwesomeIcon icon={faPlus} className="h-4" />
                    </ControlButton>
                    <ControlButton
                        className="w-8"
                        onClick={() => toggle("MINIMAP")}
                    >
                        <FontAwesomeIcon icon={faMapLocation} className="h-4" />
                    </ControlButton>
                    <ControlButton className="w-8 rounded-b" onClick={zoomOut}>
                        <FontAwesomeIcon icon={faMinus} className="h-4" />
                    </ControlButton>
                </div>

                <ControlButton
                    className="w-8 rounded"
                    onClick={() => toggle("LEGEND")}
                >
                    <FontAwesomeIcon icon={faCircleInfo} className="h-4" />
                </ControlButton>
            </div>
        </ControlContainer>
    );
};
