import { ArrowPathIcon } from "@heroicons/react/24/solid";
import StyledLink from "../Link";
import { ROUTES } from "../../routes";

interface Props {
    email: string;
    onChangeEmail: (string) => void;
    password: string;
    onChangePassword: (string) => void;
    loading: boolean;
    error?: string;
    performLogin: () => void;
}

export const LoginForm = (props: Props) => (
    <>
        <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
        </h2>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
                <div>
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            disabled={props.loading}
                            value={props.email}
                            onChange={(e) =>
                                props.onChangeEmail(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !props.loading) {
                                    props.performLogin();
                                }
                            }}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Password
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            disabled={props.loading}
                            value={props.password}
                            onChange={(e) =>
                                props.onChangePassword(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !props.loading) {
                                    props.performLogin();
                                }
                            }}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mt-1 first-letter:text-sm flex justify-end">
                        <StyledLink
                            to={`${ROUTES.PASSWORD_RESET}?email=${props.email}`}
                        >
                            Forgot password?
                        </StyledLink>
                    </div>
                </div>

                {props.error && (
                    <div className="px-4 py-2 bg-red-500 rounded-xl text-white">
                        {props.error}
                    </div>
                )}

                <div>
                    <button
                        type="submit"
                        disabled={props.loading}
                        onClick={props.performLogin}
                        className="flex w-full justify-center rounded-md transition-all duration-800 bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-700"
                    >
                        {props.loading ? (
                            <ArrowPathIcon className="animate-spin h-6" />
                        ) : (
                            "Sign in"
                        )}
                    </button>
                </div>
            </div>
        </div>
    </>
);
