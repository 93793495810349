/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `CREATED` - Created
 * * `READY_FOR_PROCESSING` - Ready For Processing
 * * `IMPORTING` - Importing
 * * `ERROR` - Error
 * * `WAITING_APPROVAL` - Waiting Approval
 * * `COMPLETE` - Complete
 * @export
 */
export const StatusEnum = {
    Created: 'CREATED',
    ReadyForProcessing: 'READY_FOR_PROCESSING',
    Importing: 'IMPORTING',
    Error: 'ERROR',
    WaitingApproval: 'WAITING_APPROVAL',
    Complete: 'COMPLETE'
} as const;
export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


export function instanceOfStatusEnum(value: any): boolean {
    return Object.values(StatusEnum).includes(value);
}

export function StatusEnumFromJSON(json: any): StatusEnum {
    return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusEnum {
    return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
    return value as any;
}

