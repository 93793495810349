import { useAppSelector, useDatauploadApiClient } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { CompanyUploadedDataPackage } from "../../apiClient/generated";
import {
    ArrowUpTrayIcon,
    DocumentTextIcon,
    ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { SearchInput } from "../../ui/Inputs";
import { DataTableV3, RowActionButtons } from "../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../DataTable/state";

const columnHelper = createColumnHelper<CompanyUploadedDataPackage>();

interface DataUploadApiDataTableProps {
    viewDetails: (uploadId: string) => void;
}

export const DataUploadApiDataTable = (props: DataUploadApiDataTableProps) => {
    // API Client
    const apiClient = useDatauploadApiClient();

    // Filters
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // User permissions
    const userIsStaff = useAppSelector((state) => state.auth.isStaff);
    const hasEditPermissions = useAppSelector(
        (state) => state.auth.permissions.dataUploadsEdit,
    );

    // Set initial table state
    useAtom(
        tableStateFamily({
            tableId: "datauploads",
            initialState: {
                sorting: {
                    desc: true,
                    id: "uploaded_at",
                },
            },
        }),
    );

    // Download archive action
    const downloadArchive = async (dataPackageId: string) => {
        const response =
            await apiClient.datauploadDataPackageDownloadArchiveUrlRetrieve({
                id: dataPackageId,
            });
        window.open(response.archiveUrl);
    };

    // Column settings
    const columns = [
        columnHelper.display({
            id: "status",
            header: () => "Status",
            size: 150,
            cell: (info) => {
                const row = info.row.original;
                if (row.virusDetected) {
                    return (
                        <div className="flex items-center">
                            <div className="h-3 w-3 mr-2 rounded-full bg-red-500" />
                            Virus detected
                        </div>
                    );
                }
                if (row.uploadComplete) {
                    return (
                        <div className="flex items-center">
                            <div className="h-3 w-3 mr-2 rounded-full bg-green-500" />
                            Complete
                        </div>
                    );
                }
                return (
                    <div className="flex items-center">
                        <div className="h-3 w-3 mr-2 rounded-full bg-yellow-500" />
                        Pending
                    </div>
                );
            },
        }),
        columnHelper.accessor("description", {
            id: "description",
            cell: (info) => info.getValue(),
            header: () => "Description",
        }),
        columnHelper.accessor("uploadedByName", {
            id: "uploadedByName",
            meta: {
                sortingKey: "uploaded_by",
            },
            header: () => "Uploaded By",
            cell: (info) => info.renderValue(),
            size: 200,
        }),
        columnHelper.accessor("uploadedAt", {
            id: "uploadedAt",
            header: () => "Uploaded At",
            size: 200,
            cell: (info) => {
                const value = info.renderValue();
                const datetime = DateTime.fromJSDate(value);

                if (value.toDateString() === new Date().toDateString()) {
                    return (
                        <>
                            <span className="capitalize">
                                {datetime.toRelativeCalendar()}
                            </span>
                            {", "}
                            {datetime.toRelative({ style: "long" })}
                        </>
                    );
                }

                return datetime.toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS,
                );
            },
        }),
        columnHelper.display({
            id: "actions",
            header: () => "",
            size: 220,
            cell: (info) => {
                const actions = [];
                if (info.row.original.uploadComplete) {
                    actions.push({
                        fn: () => props.viewDetails(info.row.original.id),
                        icon: <DocumentTextIcon className="h-5 w-5" />,
                        tooltip: "View details",
                    });
                    if (userIsStaff && info.row.original.archiveAvailable) {
                        actions.push({
                            fn: () => downloadArchive(info.row.original.id),
                            icon: <ArrowDownTrayIcon className="h-5 w-5" />,
                            tooltip: "Download Archive",
                        });
                    }
                } else {
                    if (hasEditPermissions) {
                        actions.push({
                            fn: () => props.viewDetails(info.row.original.id),
                            icon: <ArrowUpTrayIcon className="h-5 w-5" />,
                            tooltip: "Upload files",
                        });
                    }
                }

                return <RowActionButtons actions={actions} />;
            },
        }),
    ];

    const fetchFunction = async (props: any) => {
        return await apiClient.datauploadDataPackageList(props);
    };

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <div className="flex items-center">
                    <SearchInput
                        value={filterSearch}
                        placeholder="Search by description, notes or user"
                        onChange={(newValue) => setFilterSearch(newValue)}
                        className="w-96"
                    />
                </div>
            </div>

            {/* Data table */}
            <DataTableV3<CompanyUploadedDataPackage>
                dataName="datauploads"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={false}
            />
        </div>
    );
};
