import { SUPPORT_EMAIL } from "../constants/globals";
import aerscapeLogo from "./../assets/img/aerscape.png";
import aershedLogo from "./../assets/img/aershed-logo-horizontal.png";
import { ROUTES } from "../routes";
import { ReactNode } from "react";

interface LoginPageLayoutProps {
    children: ReactNode;
}

export const LoginPageLayout = (props: LoginPageLayoutProps) => {
    return (
        <div className="bg-white min-h-screen w-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-15 w-auto"
                        src={aershedLogo}
                        alt="Aerscape"
                    />
                </div>

                {props.children}

                <p className="mt-10 text-center text-sm text-gray-500">
                    First time?{" "}
                    <a
                        href={`${ROUTES.PASSWORD_RESET}?onboarding=true`}
                        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                    >
                        Start here.
                    </a>
                </p>

                <p className="mt-2 text-center text-sm text-gray-500">
                    Trouble logging in?{" "}
                    <a
                        href={`mailto:${SUPPORT_EMAIL}`}
                        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                    >
                        Contact help.
                    </a>
                </p>

                <p className="mt-2 text-center text-sm text-gray-500">
                    Not a client?{" "}
                    <a
                        href="mailto:aerscape-sales@aerscape.com"
                        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                    >
                        Contact sales.
                    </a>
                </p>

                <p className="mt-10 mb-2 text-center text-sm text-gray-500">
                    Built by
                </p>
                <a
                    href="https://aerscape.com"
                    className="sm:mx-auto sm:w-full sm:max-w-sm"
                >
                    <img
                        className="mx-auto h-7 w-auto"
                        src={aerscapeLogo}
                        alt="Aerscape"
                    />
                </a>
            </div>
            <div className="absolute bottom-0 right-0 text-sm text-gray-400">
                Build number: {__BUILD_DATE__}
            </div>
        </div>
    );
};
