/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ImportTypeEnum } from './ImportTypeEnum';
import {
    ImportTypeEnumFromJSON,
    ImportTypeEnumFromJSONTyped,
    ImportTypeEnumToJSON,
} from './ImportTypeEnum';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';

/**
 * Serializer for the DataImport model.
 * @export
 * @interface DataImport
 */
export interface DataImport {
    /**
     * 
     * @type {ImportTypeEnum}
     * @memberof DataImport
     */
    importType: ImportTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof DataImport
     */
    importParams?: any;
    /**
     * 
     * @type {string}
     * @memberof DataImport
     */
    readonly id: string;
    /**
     * 
     * @type {StatusEnum}
     * @memberof DataImport
     */
    status?: StatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof DataImport
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DataImport
     */
    createdBy: number;
    /**
     * 
     * @type {any}
     * @memberof DataImport
     */
    readonly importErrors: any | null;
}

/**
 * Check if a given object implements the DataImport interface.
 */
export function instanceOfDataImport(value: object): boolean {
    if (!('importType' in value)) return false;
    if (!('id' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('createdBy' in value)) return false;
    if (!('importErrors' in value)) return false;
    return true;
}

export function DataImportFromJSON(json: any): DataImport {
    return DataImportFromJSONTyped(json, false);
}

export function DataImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataImport {
    if (json == null) {
        return json;
    }
    return {
        
        'importType': ImportTypeEnumFromJSON(json['import_type']),
        'importParams': json['import_params'] == null ? undefined : json['import_params'],
        'id': json['id'],
        'status': json['status'] == null ? undefined : StatusEnumFromJSON(json['status']),
        'createdAt': (new Date(json['created_at'])),
        'createdBy': json['created_by'],
        'importErrors': json['import_errors'],
    };
}

export function DataImportToJSON(value?: Omit<DataImport, 'id'|'created_at'|'import_errors'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'import_type': ImportTypeEnumToJSON(value['importType']),
        'import_params': value['importParams'],
        'status': StatusEnumToJSON(value['status']),
        'created_by': value['createdBy'],
    };
}

