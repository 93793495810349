import { atomFamily, atomWithReset } from "jotai/utils";

export type TableDateFilterState = {
    type: "date";
    after?: Date;
    before?: Date;
};

export type LocationFilterState = {
    type: "location";
    geometry?: any;
    publicPresets?: number[];
};

interface FilterState {
    [key: string]: string | number | TableDateFilterState | LocationFilterState;
}

export interface TableState {
    /**
     * Filters
     *
     * To be stored exactly as needed by the API endpoints.
     * Can be manipulated freely.
     */
    filters: FilterState;
    /**
     * Sorting state.
     *
     * Either undefined (default API sorting), or a combination
     * of sorting key + if it's ascending or descending order.
     *
     */
    sorting?: {
        id: string;
        desc: boolean;
    };
    /**
     * Pagination
     *
     * Can be controlled from anywhere in the page.
     * Frontend pagination starts at page 0, backend pagination starts from page 1.
     *
     * TODO: fix pagination bug when filters are selected.
     */
    pagination: {
        pageIndex: number;
        pageSize: number;
    };
    /**
     * Column visibility
     *
     * Allows customizing which columns are visible in the table,
     * using the `id` identifier.
     */
    columnVisibility?: {
        [key: string]: boolean;
    };
}

interface TableStateFamily {
    tableId: string;
    initialState?: Partial<TableState>;
}

// Generic atom that allows us to generate custom table
// states using the same basic structure.
export const tableStateFamily = atomFamily(
    ({ initialState }: TableStateFamily) =>
        atomWithReset<TableState>({
            filters: {},
            sorting: undefined,
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
            ...initialState,
        }),
    (a, b) => a.tableId === b.tableId,
);

export const filterStateToApiParams = (filterState: FilterState) => {
    const apiFilterValues = {};
    for (const [key, value] of Object.entries(filterState)) {
        // If the value is an object, check which filter state is is and
        // perform the appropriate conversions.
        if (
            typeof value === "object" &&
            !Array.isArray(value) &&
            value !== null
        ) {
            // Handle date types
            if (value.type === "date") {
                apiFilterValues[`${key}Before`] = value.before;
                apiFilterValues[`${key}After`] = value.after;
            }

            // Handle location filter (fixme: make keys configurable)
            if (value.type === "location") {
                apiFilterValues["locationWithin"] = value.geometry;
                apiFilterValues["locationPreset"] = value.publicPresets;
            }
        } else {
            // Else add normally to array.
            apiFilterValues[key] = value;
        }
    }
    return apiFilterValues;
};
