/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `EMISSIONS` - Emissions
 * * `INFRASTRUCTURE` - Infrastructure
 * @export
 */
export const ImportTypeEnum = {
    Emissions: 'EMISSIONS',
    Infrastructure: 'INFRASTRUCTURE'
} as const;
export type ImportTypeEnum = typeof ImportTypeEnum[keyof typeof ImportTypeEnum];


export function instanceOfImportTypeEnum(value: any): boolean {
    return Object.values(ImportTypeEnum).includes(value);
}

export function ImportTypeEnumFromJSON(json: any): ImportTypeEnum {
    return ImportTypeEnumFromJSONTyped(json, false);
}

export function ImportTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportTypeEnum {
    return json as ImportTypeEnum;
}

export function ImportTypeEnumToJSON(value?: ImportTypeEnum | null): any {
    return value as any;
}

