/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmissionEvent } from './EmissionEvent';
import {
    EmissionEventFromJSON,
    EmissionEventFromJSONTyped,
    EmissionEventToJSON,
} from './EmissionEvent';
import type { EmissionStatusEnum } from './EmissionStatusEnum';
import {
    EmissionStatusEnumFromJSON,
    EmissionStatusEnumFromJSONTyped,
    EmissionStatusEnumToJSON,
} from './EmissionStatusEnum';
import type { SimpleDataPoint } from './SimpleDataPoint';
import {
    SimpleDataPointFromJSON,
    SimpleDataPointFromJSONTyped,
    SimpleDataPointToJSON,
} from './SimpleDataPoint';
import type { SimpleInfrastructure } from './SimpleInfrastructure';
import {
    SimpleInfrastructureFromJSON,
    SimpleInfrastructureFromJSONTyped,
    SimpleInfrastructureToJSON,
} from './SimpleInfrastructure';

/**
 * Serializer for listing data for EmissionMatch model.
 * @export
 * @interface EmissionRecordView
 */
export interface EmissionRecordView {
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionRecordView
     */
    readonly owner: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    readonly ownerName: string;
    /**
     * 
     * @type {EmissionStatusEnum}
     * @memberof EmissionRecordView
     */
    emissionStatus: EmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    infrastructure?: string;
    /**
     * 
     * @type {SimpleInfrastructure}
     * @memberof EmissionRecordView
     */
    infrastructureData: SimpleInfrastructure;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    infrastructureDetail?: string;
    /**
     * 
     * @type {Array<EmissionEvent>}
     * @memberof EmissionRecordView
     */
    events: Array<EmissionEvent>;
    /**
     * 
     * @type {Array<SimpleInfrastructure>}
     * @memberof EmissionRecordView
     */
    matches: Array<SimpleInfrastructure>;
    /**
     * 
     * @type {SimpleDataPoint}
     * @memberof EmissionRecordView
     */
    dataPoint: SimpleDataPoint;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    readonly providerName: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordView
     */
    readonly rejectReason: string;
    /**
     * 
     * @type {Date}
     * @memberof EmissionRecordView
     */
    readonly createdAt: Date;
}

/**
 * Check if a given object implements the EmissionRecordView interface.
 */
export function instanceOfEmissionRecordView(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('ownerName' in value)) return false;
    if (!('emissionStatus' in value)) return false;
    if (!('infrastructureData' in value)) return false;
    if (!('events' in value)) return false;
    if (!('matches' in value)) return false;
    if (!('dataPoint' in value)) return false;
    if (!('providerName' in value)) return false;
    if (!('rejectReason' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function EmissionRecordViewFromJSON(json: any): EmissionRecordView {
    return EmissionRecordViewFromJSONTyped(json, false);
}

export function EmissionRecordViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionRecordView {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'ownerName': json['owner_name'],
        'emissionStatus': EmissionStatusEnumFromJSON(json['emission_status']),
        'infrastructure': json['infrastructure'] == null ? undefined : json['infrastructure'],
        'infrastructureData': SimpleInfrastructureFromJSON(json['infrastructure_data']),
        'infrastructureDetail': json['infrastructure_detail'] == null ? undefined : json['infrastructure_detail'],
        'events': ((json['events'] as Array<any>).map(EmissionEventFromJSON)),
        'matches': ((json['matches'] as Array<any>).map(SimpleInfrastructureFromJSON)),
        'dataPoint': SimpleDataPointFromJSON(json['data_point']),
        'providerName': json['provider_name'],
        'rejectReason': json['reject_reason'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function EmissionRecordViewToJSON(value?: Omit<EmissionRecordView, 'id'|'owner'|'owner_name'|'provider_name'|'reject_reason'|'created_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emission_status': EmissionStatusEnumToJSON(value['emissionStatus']),
        'infrastructure': value['infrastructure'],
        'infrastructure_data': SimpleInfrastructureToJSON(value['infrastructureData']),
        'infrastructure_detail': value['infrastructureDetail'],
        'events': ((value['events'] as Array<any>).map(EmissionEventToJSON)),
        'matches': ((value['matches'] as Array<any>).map(SimpleInfrastructureToJSON)),
        'data_point': SimpleDataPointToJSON(value['dataPoint']),
    };
}

