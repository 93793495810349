import {
    InfraTypeEnum,
    PipelineProductEnum,
    PipelineTypeEnum,
} from "../../apiClient/generated";
import { BasemapList } from "../Map/basemaps";
import { atomFamily, atomWithReset, atomWithStorage } from "jotai/utils";

type MapViewState = {
    longitude: number;
    latitude: number;
    zoom: number;
    bearing: number;
    pitch: number;
};

export interface MapState {
    _viewState: MapViewState;
    debounced: {
        viewState?: MapViewState;
        areaOnScreen?: any;
    };
    basemap: BasemapList | null;
}

interface MapStateFamily {
    mapId: string;
    initialState?: Partial<MapState>;
}

// Generic atom that allows us to generate custom map
// states using the same basic structure.
export const mapStateFamily = atomFamily(
    ({ initialState }: MapStateFamily) =>
        atomWithReset<MapState>({
            _viewState: {
                longitude: -98.58,
                latitude: 39.82,
                zoom: 4,
                bearing: 0,
                pitch: 0,
            },
            debounced: {},
            basemap: null,
            ...initialState,
        }),
    (a, b) => a.mapId === b.mapId,
);

/**
 * Map Data State
 *
 * Used for things that are strictly done in the frontend.
 * Examples: sidebar state, plume opacity states, etc.
 *
 */
export type MapSelectedContext = {
    infrastructureId?: string;
    pipeline?: {
        id: string;
        coordinates: number[];
    };
    emissionRecordId?: string;
    dataPointId?: string;
    // Related plume: controls behavior of plumes showing on map.
    // * String: plume ID to display
    // * null: hide all plumes
    // * undefined: show all plumes
    relatedPlume?: string | null;
};

export interface MapDataState {
    selectedContext: MapSelectedContext;
    mapSettings: {
        plumeOpacity: number;
    };
}

interface MapDataStateFamily {
    mapId: string;
    initialState?: Partial<MapDataState>;
}

export const mapDataStateFamily = atomFamily(
    ({ initialState }: MapDataStateFamily) =>
        atomWithReset<MapDataState>({
            selectedContext: {},
            mapSettings: {
                plumeOpacity: 0.5,
            },
            ...initialState,
        }),
    (a, b) => a.mapId === b.mapId,
);

/**
 * MapFiltersState
 *
 * Used strictly for keeping state related to data-fetching
 * mechanisms and filters that can be applied on the backend
 * directly.
 */
export interface MapFiltersState {
    infrastructure: {
        showInfrastructure: boolean;
        infraTypeFilter: InfraTypeEnum[];
        pipelineProduct: PipelineProductEnum[];
        pipelineType: PipelineTypeEnum[];
    };
    emissions: {
        showEmissions: boolean;
        showPublicEmissions: boolean;
        emissionGroupFilters: {
            thirdParty: string[] | "all";
            thirdPartyPublic: string[] | "all";
            selfReported: string[] | "all";
            epa: string[] | "all";
        };
        // Store dates as ISO formatted dates to avoid serialization issues.
        startDateFilter: string;
        endDateFilter: string;
    };
    geospatialFilters: {
        shape?: any;
        predefinedAreas?: number[];
        filterData: boolean;
    };
}

const defaultMapFilterState: MapFiltersState = {
    infrastructure: {
        showInfrastructure: true,
        infraTypeFilter: [InfraTypeEnum.Site, InfraTypeEnum.Equipment],
        pipelineProduct: [
            PipelineProductEnum.NaturalGas,
            PipelineProductEnum.Ngl,
        ],
        pipelineType: [
            PipelineTypeEnum.Distribution,
            PipelineTypeEnum.Gathering,
            PipelineTypeEnum.Transmission,
        ],
    },
    emissions: {
        showEmissions: true,
        showPublicEmissions: true,
        emissionGroupFilters: {
            thirdParty: "all",
            thirdPartyPublic: "all",
            selfReported: "all",
            epa: "all",
        },
        startDateFilter: new Date(
            new Date().setMonth(new Date().getMonth() - 12),
        ).toISOString(),
        endDateFilter: new Date().toISOString(),
    },
    geospatialFilters: {
        filterData: false,
    },
};

interface MapFiltersStateFamily {
    mapId: string;
    initialState?: Partial<MapFiltersState>;
}

export const mapFiltersStateFamily = atomFamily(
    ({ initialState }: MapFiltersStateFamily) =>
        atomWithStorage<MapFiltersState>(
            "mapFilterState",
            {
                ...defaultMapFilterState,
                ...initialState,
            },
            undefined,
            {
                getOnInit: !initialState,
            },
        ),
    (a, b) => a.mapId === b.mapId,
);
