import {
    Infrastructure,
    InfrastructureMapList,
} from "../../../../../apiClient/generated";
import { GeoJsonLayer } from "@deck.gl/layers";
import { createFeatureCollection } from "../../../../../utils/geopatialUtils";
import { useInfrastructureApiClient } from "../../../../../hooks";
import { useEffect, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import {
    AlignedDataDisplay,
    CoordinatesField,
} from "../../../../../ui/GenericFields";

interface LayerColorMapType {
    [key: string]: {
        fillColor: [number, number, number, number];
        lineColor: [number, number, number, number];
        highlightColor?: [number, number, number, number];
        lineWidth: number;
        pointRadius?: number;
    };
}

export const InfrastructureOnlyLayerColorMap: LayerColorMapType = {
    PIPELINE: {
        fillColor: [129, 50, 250, 0],
        lineColor: [0, 0, 0, 255],
        lineWidth: 5,
        pointRadius: 8,
    },
    SITE: {
        fillColor: [255, 0, 0, 255],
        lineColor: [0, 0, 0, 255],
        lineWidth: 3,
        highlightColor: [0, 0, 0, 20],
        pointRadius: 8,
    },
    EQUIPMENT_GROUP: {
        fillColor: [200, 200, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 6,
    },
    EQUIPMENT: {
        fillColor: [0, 200, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 4,
    },
    EQUIPMENT_COMPONENT: {
        fillColor: [0, 0, 255, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 2,
    },
};

const layerColorMap: LayerColorMapType = {
    SITE: {
        fillColor: [0, 0, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        highlightColor: [0, 0, 0, 20],
    },
    PIPELINE: {
        fillColor: [0, 0, 0, 0],
        lineColor: [0, 0, 0, 255],
        lineWidth: 3,
    },
    EQUIPMENT: {
        fillColor: [20, 20, 20, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 1,
    },
};

interface InfrastructureCardProps {
    infrastructureId: string;
}

export const InfrastructureItemDisplay = (props: InfrastructureCardProps) => {
    const apiClient = useInfrastructureApiClient();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<undefined | Infrastructure>(undefined);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const response = await apiClient.infrastructureListRetrieve({
                id: props.infrastructureId,
            });
            setData(response);
            setLoading(false);
        };
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading || !data) {
        return (
            <div className="flex w-full justify-center">
                <ArrowPathIcon className="p-1 h-8 animate-spin text-slate-500"></ArrowPathIcon>
            </div>
        );
    }

    return (
        <AlignedDataDisplay
            data={[
                {
                    label: "Type:",
                    value: data?.infraType,
                },
                {
                    label: "Name:",
                    value: data?.name,
                },
                {
                    label: "Site Name:",
                    value: data?.siteName,
                },
                {
                    label: "Location:",
                    value: (
                        <CoordinatesField
                            coordinates={data.location.coordinates}
                            allowCopy={true}
                        />
                    ),
                },
                {
                    label: "Owner:",
                    value: data?.ownerName,
                },
            ]}
        />
    );
};

export const InfrastructureConfigurationPanel = () => {
    return (
        <div>
            <span className="flex items-center">
                <div className={`mr-2 h-4 w-4 rounded bg-black`} />
                Infrastructure (Points)
            </span>
            <span className="flex items-center">
                <div className={`mr-2 h-4 w-4 rounded border-black border-2`} />
                Infrastructure (Areas)
            </span>
            <span className="flex items-center">
                <div className={`mr-2 h-1 w-4 rounded border-black border-2`} />
                Pipelines
            </span>
        </div>
    );
};

type Props = {
    data: InfrastructureMapList[];
    enabledLayers: string[];
    onHover?: (hoverInfo: any) => void;
};

export const InfrastructureLayer = (props: Props) => {
    return [
        new GeoJsonLayer({
            id: "infrastructure",
            data: createFeatureCollection(props.data),
            pointType: "circle",
            filled: true,
            getPointRadius: 6,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: true,
            visible: props.enabledLayers.includes("infrastructure"),
            autoHighlight: true,
            onHover: props.onHover,
            highlightColor: (item) => {
                return (
                    layerColorMap[item.object.properties!.infraType]
                        ?.highlightColor || [0, 0, 0, 30]
                );
            },
            getFillColor: (item) => {
                if (item.properties!.shape) {
                    return [0, 0, 0, 0];
                }
                return (
                    layerColorMap[item.properties!.infraType]?.fillColor || [
                        0, 255, 255, 255,
                    ]
                );
            },
            getLineWidth: (item) => {
                return (
                    layerColorMap[item.properties!.infraType]?.lineWidth || 1
                );
            },
            getLineColor: (item) => {
                if (item.properties!.shape) {
                    return (
                        layerColorMap[item.properties!.infraType]
                            ?.fillColor || [0, 0, 0, 255]
                    );
                }
                return (
                    layerColorMap[item.properties!.infraType]?.lineColor || [
                        0, 255, 255, 255,
                    ]
                );
            },
        }),
    ];
};
