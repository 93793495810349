import { CheckIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import {
    AdminInfrastructureImportItemLocation,
    InfrastructureImportItem,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";
import { DetailViewEditableCell } from "./EditableCells";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { RadioSelector } from "../../Radio";
import { useQuery } from "@tanstack/react-query";
import { CoordinatesField } from "../../GenericFields";
import { GeoJSONInfrastructurePreviewMiniMap } from "../../../components/Map/MiniMap";
import type { FeatureCollection } from "geojson";

const mergeOptions = [
    {
        key: "mergeAll",
        label: "Merge lines",
        extraContent:
            "Merge lines and only create one infrastructure item, all relationships will be transferred over.",
    },
    {
        key: "keepAll",
        label: "Keep lines separate",
        extraContent:
            "Do not merge lines and assume they are individual sites, keeping their properties untouched.",
    },
];

interface InfrastructureComparisonTableProps {
    similarRows: InfrastructureImportItem[];
    mainRow: number;
    setMainRow: (value: number) => any;
    rowsToMerge: number[];
    toggleRowToMerge: (value: number) => any;
    highlight?: number;
}

const InfrastructureComparisonTable = (
    props: InfrastructureComparisonTableProps,
) => {
    const { similarRows, mainRow, setMainRow, rowsToMerge, toggleRowToMerge } =
        props;
    const tableKeys = [
        {
            key: "infraType",
            label: "Type",
            renderer: (value: string) =>
                value.replaceAll("_", " ").toLowerCase(),
        },
        {
            key: "placeholderId",
            label: "Temp ID",
            renderer: (value: string) => value,
        },
        {
            key: "location",
            label: "Location",
            renderer: (value: AdminInfrastructureImportItemLocation) =>
                value && <CoordinatesField coordinates={value.coordinates} />,
        },
        {
            key: "shape",
            label: "Has shape",
            renderer: (value?: any) => (value ? "Yes" : "No"),
        },
        {
            key: "infraStatus",
            label: "Status",
            renderer: (value: string) =>
                value.replaceAll("_", " ").toLowerCase(),
        },
    ];

    return (
        <table className="table-auto border-collapse border border-slate-200 whitespace-nowrap">
            <thead>
                <tr>
                    <th className="px-4 py-2 border border-slate-200">
                        Site name
                    </th>
                    {similarRows.map((obj, index) => (
                        <th
                            key={index}
                            className={`
                                px-4 py-2 border border-slate-200
                                    ${
                                        props.highlight === obj.id &&
                                        "bg-slate-300"
                                    }
                            `}
                        >
                            {obj.siteName}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tableKeys.map((key) => (
                    <tr key={key.key}>
                        <td className="px-4 py-2 border border-slate-200">
                            {key.label}
                        </td>
                        {similarRows.map((obj, index) => (
                            <td
                                key={index}
                                className={`
                                    capitalize px-4 py-2 border border-slate-200
                                    ${
                                        props.highlight === obj.id &&
                                        "bg-slate-300"
                                    }
                                `}
                            >
                                {key.renderer(obj[key.key])}
                            </td>
                        ))}
                    </tr>
                ))}
                <tr>
                    <td className="px-4 py-2 border border-slate-200 font-bold">
                        Items to merge
                    </td>
                    {similarRows.map((obj, index) => (
                        <td
                            className={`
                            capitalize px-4 py-2 border border-slate-200
                                    ${
                                        props.highlight === obj.id &&
                                        "bg-slate-300"
                                    }
                        `}
                            key={index}
                        >
                            <div className="w-full flex items-center justify-center">
                                <input
                                    type="checkbox"
                                    checked={rowsToMerge.includes(obj.id)}
                                    onChange={() => toggleRowToMerge(obj.id)}
                                    className="text-ae-blue-500 rounded"
                                />
                            </div>
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className="px-4 py-2 border border-slate-200 font-bold">
                        Keep data from
                    </td>
                    {similarRows.map((obj, index) => (
                        <td
                            className={`
                                    capitalize px-4 py-2 border border-slate-200
                                    ${
                                        props.highlight === obj.id &&
                                        "bg-slate-300"
                                    }
                                `}
                            key={index}
                        >
                            <div className="w-full flex items-center justify-center">
                                <input
                                    type="radio"
                                    name="main"
                                    checked={mainRow === obj.id}
                                    onChange={() => setMainRow(obj.id)}
                                    disabled={!rowsToMerge.includes(obj.id)}
                                    className="text-ae-blue-500"
                                />
                            </div>
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};

interface EditingViewProps {
    itemId: number;
    mergeOptions: string;
    onChangeMergeOption: (option: string) => void;
    mainRow: number;
    setMainRow: (value: number) => any;
    rowsToMerge: number[];
    toggleRowToMerge: (value: number) => any;
}

const EditingView = (props: EditingViewProps) => {
    const apiClient = useInfrastructureApiClient();
    const [hoverSite, setHoverSite] = useState(undefined);

    const similarRows = useQuery({
        queryKey: ["infraBuilderSimilar", props.itemId],
        queryFn: () =>
            apiClient.infrastructureImportItemsGetSimilarRowsList({
                id: props.itemId,
            }),
        refetchOnMount: "always",
    });

    const dataAsGeoJson = useMemo(() => {
        const features = [];
        if (similarRows.data) {
            similarRows.data.forEach((row) => {
                // Try to extract some data
                const properties = {
                    id: row.id,
                    facility_name: row.siteName,
                    infra_type: (row.infraType || "")
                        .replace(" ", " ")
                        .toUpperCase(),
                    equipment_type: row["Equipment Type"],
                };

                features.push({
                    type: "Feature",
                    properties,
                    geometry: row.shape || row.location,
                });
            });
        }
        return {
            type: "FeatureCollection",
            features,
        } as FeatureCollection<any>;
    }, [similarRows.data]);

    return (
        <div>
            <p className="w-full flex justify-left text-md mb-3">
                This row has a possible duplicate in this import.
            </p>
            {similarRows.isLoading ? (
                <div className="flex items-center mb-4 animate-pulse">
                    <ArrowPathIcon className="mr-2 w-4 h-4 animate-spin" />
                    Loading matching rows...
                </div>
            ) : (
                <div className="text-sm flex gap-4">
                    <div className="w-2/3 overflow-x-scroll">
                        <InfrastructureComparisonTable
                            similarRows={similarRows.data}
                            mainRow={props.mainRow}
                            setMainRow={(v) => props.setMainRow(v)}
                            rowsToMerge={props.rowsToMerge}
                            toggleRowToMerge={props.toggleRowToMerge}
                            highlight={hoverSite}
                        />
                    </div>
                    <div className="w-2/3 relative h-96 rounded-lg overflow-hidden">
                        <GeoJSONInfrastructurePreviewMiniMap
                            geojson={dataAsGeoJson}
                            onHover={(id) => setHoverSite(id)}
                        />
                    </div>
                </div>
            )}
            <p className="w-full flex justify-left text-md mt-4 mb-2">
                Select how would you like the import to process these rows.
            </p>
            <div className="w-4/5">
                <RadioSelector
                    value={props.mergeOptions}
                    onChange={props.onChangeMergeOption}
                    options={mergeOptions}
                />
            </div>
        </div>
    );
};

interface LineSimilarityCellProps {
    itemId: number;
    lineDuplicatesId?: number;
    save: (
        mergeItems: boolean,
        mainItemId: number,
        itemsToMerge?: number[],
    ) => Promise<any>;
}

export const LineSimilarityCell = (props: LineSimilarityCellProps) => {
    const [mergeOption, setMergeOption] = useState("mergeAll");
    const [rowsToMerge, setRowsToMerge] = useState<number[]>([props.itemId]);
    const [mainRow, setMainRow] = useState<number>(props.itemId);

    const toggleRowToMerge = (value: number) => {
        setRowsToMerge((rows) => {
            if (rows.includes(value)) {
                return rows.filter((i) => i !== value);
            }
            return [...rows, value];
        });
    };

    const onChange = () => {
        return props.save(mergeOption === "mergeAll", mainRow, rowsToMerge);
    };

    return (
        <div>
            {!props.lineDuplicatesId ? (
                <div className="flex items-center justify-center text-gray-400">
                    No match
                    <CheckIcon className="ml-2 w-4 h-4 text-green-600" />
                </div>
            ) : (
                <DetailViewEditableCell
                    title="Review similar lines"
                    onCancel={() => {}}
                    onSave={onChange}
                >
                    {(editing) =>
                        editing ? (
                            <EditingView
                                itemId={props.itemId}
                                mergeOptions={mergeOption}
                                onChangeMergeOption={(option) =>
                                    setMergeOption(option)
                                }
                                mainRow={mainRow}
                                rowsToMerge={rowsToMerge}
                                setMainRow={setMainRow}
                                toggleRowToMerge={toggleRowToMerge}
                            />
                        ) : (
                            "Review"
                        )
                    }
                </DetailViewEditableCell>
            )}
        </div>
    );
};
