import {
    ArrowUturnLeftIcon,
    ArrowsRightLeftIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { GeoJSONInfrastructurePreviewMiniMap } from "../../Map/MiniMap";
import { useEffect, useState } from "react";
import {
    AdminInfrastructureImport,
    ConflictMatchingStatusEnum,
    InfrastructureImport,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";
import { Tab } from "@headlessui/react";
import { RelationshipBuilderGraphView } from "../RelationshipBuilderGraphView";
import { DateTime } from "luxon";
import { MapIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import type { FeatureCollection } from "geojson";
import { AdminApprovalTab } from "./AdminApprovalTab";
import { EditableNumberCell } from "../../../ui/Table/CustomCells/EditableCells";
import { AdminTableView } from "./AdminTableView";
import {
    ArrowPathIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

export const AdminInfrastructureImportApprovalPage = () => {
    const { id } = useParams();
    const apiClient = useInfrastructureApiClient();
    const [data, setData] = useState<AdminInfrastructureImport>();
    const [geojson, setGeojson] = useState<FeatureCollection<any>>();

    const fetchData = async () => {
        const response = await apiClient.infrastructureAdminImportRetrieve({
            id,
        });
        setData(response);
    };

    const fetchMapData = async () => {
        const geojsonReponse =
            await apiClient.infrastructureImportGetGeojsonRetrieve({ id });
        setGeojson(geojsonReponse as FeatureCollection<any>);
    };

    useEffect(() => {
        if (data && data.conflictMatchingStatus === "RUNNING") {
            const interval = setInterval(async () => {
                const response =
                    await apiClient.infrastructureAdminImportRetrieve({
                        id,
                    });
                if (response.conflictMatchingStatus !== "RUNNING") {
                    fetchData();
                }
            }, 2000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [data]);

    useEffect(() => {
        fetchData();
        fetchMapData();
    }, []);

    if (!data) {
        return null;
    }

    return (
        <div className="w-full">
            <Tab.Group>
                <div className="flex justify-between py-4 px-10 items-center">
                    <h1 className="text-xl">Infrastructure Import Review</h1>

                    <Tab.List className="flex space-x-4 ">
                        <Tab
                            className={({ selected }) =>
                                `flex items-center py-1 px-2 rounded-lg ${
                                    selected
                                        ? "font-bold"
                                        : "hover:text-slate-100 hover:bg-slate-800"
                                }`
                            }
                        >
                            <DocumentTextIcon className="w-5 h-5 mr-1" />
                            Data
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `flex items-center py-1 px-2 rounded-lg ${
                                    selected
                                        ? "font-bold"
                                        : "hover:text-slate-100 hover:bg-slate-800"
                                }`
                            }
                        >
                            <MapIcon className="w-5 h-5 mr-1" />
                            Map
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `flex items-center py-1 px-2 rounded-lg ${
                                    selected
                                        ? "font-bold"
                                        : "hover:text-slate-100 hover:bg-slate-800"
                                }`
                            }
                        >
                            <ArrowsRightLeftIcon className="w-5 h-5 mr-1" />
                            Graph
                        </Tab>
                        {data.importStatus === "WAITING_REVIEW" && (
                            <Tab
                                className={({ selected }) =>
                                    `flex items-center py-1 px-2 rounded-lg ${
                                        selected
                                            ? "font-bold"
                                            : "hover:text-slate-100 hover:bg-slate-800"
                                    }`
                                }
                            >
                                <ShieldCheckIcon className="w-5 h-5 mr-1" />
                                Checklist & Approval
                            </Tab>
                        )}
                    </Tab.List>

                    <Link
                        to={ROUTES.ADMIN_INFRASTRUCURE_IMPORT}
                        className="flex hover:text-slate-600"
                    >
                        <ArrowUturnLeftIcon className="w-6 h-6 mr-2" />
                        Back to list
                    </Link>
                </div>
                <hr />

                <Tab.Panels>
                    <Tab.Panel className="bg-white">
                        <div className="flex">
                            <div className="p-6 grid grid-cols-2">
                                <div>
                                    <span className="font-bold mr-1">ID:</span>
                                    {data.id}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        Company:
                                    </span>
                                    {data.ownerName}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        Item Count:
                                    </span>
                                    {data.itemCount}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        CSV Input filename:
                                    </span>
                                    {data.csvInputFilename}
                                    {" - SRID EPSG"}
                                    {data.csvDataSrid}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        Created by:
                                    </span>
                                    {data.createdByName}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        Created at:
                                    </span>
                                    {DateTime.fromJSDate(
                                        data.createdAt,
                                    ).toLocaleString(
                                        DateTime.DATETIME_MED_WITH_SECONDS,
                                    )}
                                </div>
                                <div>
                                    <span className="font-bold mr-1">
                                        Last updated at:
                                    </span>
                                    {DateTime.fromJSDate(
                                        data.updatedAt,
                                    ).toLocaleString(
                                        DateTime.DATETIME_MED_WITH_SECONDS,
                                    )}
                                </div>
                            </div>
                            <div className="p-6 flex items-center gap-4">
                                <div>
                                    <div className="font-bold flex items center">
                                        Cross operator matching
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span>Conflict tolerance (m):</span>
                                        {data.conflictMatchingStatus ===
                                            ConflictMatchingStatusEnum.Running ||
                                        data.conflictMatchingStatus ===
                                            ConflictMatchingStatusEnum.Stopped ? (
                                            data.crossOperatorConflictDistance
                                        ) : (
                                            <EditableNumberCell
                                                initialValue={
                                                    data.crossOperatorConflictDistance
                                                }
                                                onChange={async (newValue) => {
                                                    await apiClient.infrastructureAdminImportPartialUpdate(
                                                        {
                                                            id: data.id,
                                                            patchedAdminInfrastructureImportRequest:
                                                                {
                                                                    crossOperatorConflictDistance:
                                                                        newValue,
                                                                },
                                                        },
                                                    );
                                                    await fetchData();
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span>Warning tolerance (m):</span>
                                        {data.conflictMatchingStatus ===
                                            ConflictMatchingStatusEnum.Running ||
                                        data.conflictMatchingStatus ===
                                            ConflictMatchingStatusEnum.Stopped ? (
                                            data.crossOperatorNearMatchDistance
                                        ) : (
                                            <EditableNumberCell
                                                initialValue={
                                                    data.crossOperatorNearMatchDistance
                                                }
                                                onChange={async (newValue) => {
                                                    await apiClient.infrastructureAdminImportPartialUpdate(
                                                        {
                                                            id: data.id,
                                                            patchedAdminInfrastructureImportRequest:
                                                                {
                                                                    crossOperatorNearMatchDistance:
                                                                        newValue,
                                                                },
                                                        },
                                                    );
                                                    await fetchData();
                                                }}
                                            />
                                        )}
                                    </div>
                                    <p className="mt-1 flex items-center">
                                        Status:
                                        <span className="ml-2 capitalize">
                                            {data.conflictMatchingStatus.toLowerCase()}
                                        </span>
                                        {data.conflictMatchingStatus ===
                                            "PENDING" && (
                                            <ExclamationTriangleIcon className="ml-2 h-4 text-yellow-500" />
                                        )}
                                        {data.conflictMatchingStatus ===
                                            "COMPLETE" && (
                                            <CheckCircleIcon className="ml-2 h-4 text-green-500" />
                                        )}
                                        {data.conflictMatchingStatus ===
                                            "RUNNING" && (
                                            <ArrowPathIcon className="ml-2 h-4 text-blue-500 animate-spin" />
                                        )}
                                    </p>
                                </div>
                                <div>
                                    {data.conflictMatchingStatus ===
                                        ConflictMatchingStatusEnum.Pending && (
                                        <button
                                            className="p-2 mt-2 bg-slate-900 text-white rounded hover:bg-slate-500"
                                            onClick={async () => {
                                                await apiClient.infrastructureAdminImportRerunCrossOperatorMatchesCreate(
                                                    {
                                                        id: data.id,
                                                    },
                                                );
                                                await fetchData();
                                            }}
                                        >
                                            Re-run cross operator matching
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="pt-6">
                            <AdminTableView importData={data} />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="relative h-[80vh]">
                            <GeoJSONInfrastructurePreviewMiniMap
                                geojson={geojson}
                            />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="bg-white pt-6 h-[85vh]">
                            <RelationshipBuilderGraphView
                                importData={data as InfrastructureImport}
                            />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <AdminApprovalTab infrastructureImport={data} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
