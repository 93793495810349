import { useState, useCallback } from "react";
import axios from "axios";
import { useDatauploadApiClient } from "../../hooks";

let controller = new AbortController();
export interface UploadState {
    file: File;
    loaded?: number;
    total?: number;
    status: "waiting" | "uploading" | "success" | "error";
    error?: string;
}

export const useDataPackageUploader = (dataPackageId: string) => {
    // API client
    const apiClient = useDatauploadApiClient();

    // State tracking for uploads
    const [uploadState, setUploadState] = useState<{
        [key: string]: UploadState;
    }>({});

    // Add files ignoring any duplicates
    const addFiles = useCallback(
        (newFiles: File[]) => {
            const newUploadState = { ...uploadState };
            newFiles.forEach((f) => {
                if (newUploadState[f.name] === undefined) {
                    newUploadState[f.name] = {
                        file: f,
                        status: "waiting",
                    };
                }
            });
            setUploadState(newUploadState);
        },
        [uploadState, setUploadState],
    );

    // Remove a file
    const removeFile = useCallback(
        (filename: string) => {
            const newUploadState = { ...uploadState };
            delete newUploadState[filename];
            setUploadState(newUploadState);
        },
        [uploadState, setUploadState],
    );

    // Update state of an item (internal)
    const updateState = useCallback(
        (filename: string, data: Partial<UploadState>) => {
            setUploadState((prevState) => {
                const newUploadState = { ...prevState };
                newUploadState[filename] = {
                    ...newUploadState[filename],
                    ...data,
                };
                return newUploadState;
            });
        },
        [setUploadState],
    );

    // Download file (internal)
    const downloadFile = useCallback(
        async (state: UploadState) => {
            updateState(state.file.name, {
                status: "uploading",
            });
            try {
                // Retrieve presigned URL
                const response =
                    await apiClient.datauploadDataPackageUploadUrlCreate({
                        id: dataPackageId,
                        dataPackageFileUploadRequest: {
                            filename: state.file.name,
                        },
                    });

                // Upload file and update upload status
                await axios.put(response.uploadUrl, state.file, {
                    headers: {
                        "Content-Type": state.file.type,
                    },
                    onUploadProgress: (e) =>
                        updateState(state.file.name, {
                            status: "uploading",
                            total: e.total,
                            loaded: e.loaded,
                        }),
                    signal: controller.signal,
                });

                updateState(state.file.name, {
                    loaded: undefined,
                    total: undefined,
                    status: "success",
                });
            } catch (e) {
                let error = "Could not complete request.";
                try {
                    const errorResponse = await e.response.json();
                    error = errorResponse.error;
                } catch {
                    console.log(
                        "Failed to Unknown errorparse error message body.",
                    );
                }
                updateState(state.file.name, {
                    loaded: undefined,
                    total: undefined,
                    status: "error",
                    error: error,
                });
            }
        },
        [uploadState, setUploadState, updateState, dataPackageId, apiClient],
    );

    // Start upload process
    const startUpload = useCallback(() => {
        // Loop through all items
        for (const filename in uploadState) {
            const state = uploadState[filename];

            // Check if they are waiting upload
            if (state.status === "waiting") {
                downloadFile(state);
            }
        }
    }, [uploadState, downloadFile]);

    // Abort uploads
    const stopUploads = useCallback(() => {
        controller.abort();
        controller = new AbortController();
    }, []);

    return {
        addFiles,
        removeFile,
        startUpload,
        uploadState,
        stopUploads,
    };
};
