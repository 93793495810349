import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { MapDropdownMenuV2 } from "../../ui/MapControls";
import { useMapFilters } from "../../hooks/mapDataAndFilters";
import { CustomSwitch } from "../../../../ui/CustomSwitch";
import { useMemo } from "react";
import { useDataProvidersApi } from "../../../../hooks";
import { useQuery } from "@tanstack/react-query";
import {
    DataProvidersListRequest,
    InfraTypeEnum,
} from "../../../../apiClient/generated";
import { MenuCheckboxItem, PipelinePickerMenu } from "../../ui/Menu";

interface EmissionProviderPickerProps {
    label: string;
    selected: string[] | "all";
    setSelected: (value: string[] | "all") => void;
    providerFilters: DataProvidersListRequest;
}

const EmissionProviderPicker = (props: EmissionProviderPickerProps) => {
    const apiClient = useDataProvidersApi();

    // Query filter groups
    const providersQuery = useQuery({
        queryKey: ["dataProviders", props.providerFilters],
        queryFn: async () => {
            const response = await apiClient.dataProvidersList({
                pageSize: 50,
                ...props.providerFilters,
            });
            return response.results;
        },
        staleTime: Infinity,
    });
    const options = useMemo(() => {
        if (providersQuery.isLoading) {
            return [];
        }
        return providersQuery.data.map((i) => ({
            id: i.id,
            label: i.name,
        }));
    }, [providersQuery]);

    return (
        <MenuCheckboxItem
            label={props.label}
            options={options}
            selected={props.selected}
            setSelected={props.setSelected}
            search={true}
        />
    );
};

const EmissionsSection = () => {
    const { filterState, updateMapFilterState } = useMapFilters("mainMap");
    const emissionFilters = useMemo(
        () => filterState.emissions,
        [filterState.emissions],
    );

    return (
        <div className="text-sm">
            <div className="px-2 my-1 text-neutral-600 text-xs font-semibold leading-tight tracking-wide uppercase">
                Company Emissions
            </div>
            <div className="mt-2 flex items-center gap-2 px-2">
                <CustomSwitch
                    size="xs"
                    checked={emissionFilters.showEmissions}
                    onChange={() => {
                        updateMapFilterState((s) => {
                            s.emissions.showEmissions =
                                !s.emissions.showEmissions;
                        });
                    }}
                />
                <span className="flex-grow">Company Emissions</span>
            </div>
            <hr className="mt-2 mb-1 h-[1px] border-0 mx-2 bg-ae-slate-200" />
            <EmissionProviderPicker
                label="3rd Party Detections"
                providerFilters={{
                    dataSource: ["THIRD_PARTY"],
                }}
                selected={emissionFilters.emissionGroupFilters.thirdParty}
                setSelected={(v) =>
                    updateMapFilterState((draft) => {
                        draft.emissions.emissionGroupFilters.thirdParty = v;
                    })
                }
            />
            <EmissionProviderPicker
                label="My Company Monitoring"
                providerFilters={{
                    dataSource: ["SELF_REPORTED"],
                }}
                selected={emissionFilters.emissionGroupFilters.selfReported}
                setSelected={(v) =>
                    updateMapFilterState((draft) => {
                        draft.emissions.emissionGroupFilters.selfReported = v;
                    })
                }
            />
            <EmissionProviderPicker
                label="EPA SEP"
                providerFilters={{
                    dataSource: ["EPA"],
                }}
                selected={emissionFilters.emissionGroupFilters.epa}
                setSelected={(v) =>
                    updateMapFilterState((draft) => {
                        draft.emissions.emissionGroupFilters.epa = v;
                    })
                }
            />
        </div>
    );
};

const PublicEmissionsSection = () => {
    const { filterState, updateMapFilterState } = useMapFilters("mainMap");
    const emissionFilters = useMemo(
        () => filterState.emissions,
        [filterState.emissions],
    );

    return (
        <div className="text-sm">
            <div className="px-2 my-1 text-neutral-600 text-xs font-semibold leading-tight tracking-wide uppercase">
                Public Emissions
            </div>
            <EmissionProviderPicker
                label="Public Data"
                providerFilters={{
                    dataSource: ["THIRD_PARTY"],
                    hasData: true,
                }}
                selected={emissionFilters.emissionGroupFilters.thirdPartyPublic}
                setSelected={(v) =>
                    updateMapFilterState((draft) => {
                        draft.emissions.emissionGroupFilters.thirdPartyPublic =
                            v;
                    })
                }
            />
        </div>
    );
};

const InfrastructureSection = () => {
    const { filterState, updateMapFilterState } = useMapFilters("mainMap");
    const infrastructureFilters = useMemo(
        () => filterState.infrastructure,
        [filterState.infrastructure],
    );

    return (
        <div className="text-sm">
            <div className="px-2 my-1 text-neutral-600 text-xs font-semibold leading-tight tracking-wide uppercase">
                Infrastructure
            </div>
            <div className="mt-2 flex items-center gap-2 px-2">
                <CustomSwitch
                    size="xs"
                    checked={infrastructureFilters.showInfrastructure}
                    onChange={() => {
                        updateMapFilterState((s) => {
                            s.infrastructure.showInfrastructure =
                                !s.infrastructure.showInfrastructure;
                        });
                    }}
                />
                <span className="flex-grow">Infrastructure</span>
            </div>
            <hr className="mt-2 mb-1 h-[1px] border-0 mx-2 bg-ae-slate-200" />
            <MenuCheckboxItem
                label="Sites"
                options={[
                    {
                        id: InfraTypeEnum.Site,
                        label: "Sites",
                    },
                    {
                        id: InfraTypeEnum.Equipment,
                        label: "Equipments & Groups",
                    },
                ]}
                selected={infrastructureFilters.infraTypeFilter}
                setSelected={(v) =>
                    updateMapFilterState((s) => {
                        if (v == "all") {
                            s.infrastructure.infraTypeFilter = [
                                InfraTypeEnum.Site,
                                InfraTypeEnum.Equipment,
                            ];
                        } else if (Array.isArray(v)) {
                            s.infrastructure.infraTypeFilter =
                                v as InfraTypeEnum[];
                        }
                    })
                }
            />
            <PipelinePickerMenu
                products={infrastructureFilters.pipelineProduct}
                setProducts={(v) => {
                    updateMapFilterState((s) => {
                        s.infrastructure.pipelineProduct = v;
                    });
                }}
                types={infrastructureFilters.pipelineType}
                setTypes={(v) => {
                    updateMapFilterState((s) => {
                        s.infrastructure.pipelineType = v;
                    });
                }}
            />
        </div>
    );
};

export const MapSettingsControl = () => {
    return (
        <MapDropdownMenuV2
            button={
                <div className="flex h-8 w-36 text-sm items-center justify-between py-2 px-4 bg-white rounded border border-ae-gray-250 ui-open:border-ae-blue-550">
                    Map Settings
                    <ChevronDownIcon className="h-4" />
                </div>
            }
        >
            <div className="w-64 text-sm">
                <EmissionsSection />
                <hr className="mt-1 mb-2 h-[1px] border-0 bg-black bg-opacity-10" />
                <PublicEmissionsSection />
                <hr className="mt-1 mb-2 h-[1px] border-0 bg-black bg-opacity-10" />
                <InfrastructureSection />
            </div>
        </MapDropdownMenuV2>
    );
};
