import { DashboardCard } from "../../ui/Card";
import {
    DashboardMetric,
    DashboardStatsGroup,
} from "../../ui/DashboardComponents";
import {
    useEmissionEventsApiClient,
    useEmissionRecordsApiClient,
} from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { EmissioneventsListDataSourceEnum } from "../../apiClient/generated";
import { EmissionAndEventManagement } from "./common";

const StatsPanel = () => {
    const emissionApiClient = useEmissionRecordsApiClient();
    const eventsApiClient = useEmissionEventsApiClient();

    const emissionsQuery = useQuery({
        queryKey: ["emissionRecordStats"],
        queryFn: async () => {
            return await emissionApiClient.emissionRecordsEmissionRecordStatsRetrieve(
                {
                    dataSource: [EmissioneventsListDataSourceEnum.ThirdParty],
                },
            );
        },
    });

    const eventsQuery = useQuery({
        queryKey: ["eventStats"],
        queryFn: async () => {
            return await eventsApiClient.emissioneventsEventStatsRetrieve({
                dataSource: [EmissioneventsListDataSourceEnum.ThirdParty],
            });
        },
    });

    return (
        <div className="p-6 flex justify-between max-w-full overflow-x-scroll">
            <DashboardStatsGroup title="3rd Party Emission Events">
                <DashboardMetric
                    value={emissionsQuery.data?.lastNDays}
                    label="Added last 90 days"
                />
                <DashboardMetric
                    value={emissionsQuery.data?.total}
                    label="Total Events"
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Emission Rates">
                <DashboardMetric
                    value={`${(emissionsQuery.data?.highestRate / 1000).toFixed(
                        0,
                    )} kg/h`}
                    label={"Highest event"}
                />
                <DashboardMetric
                    value={`${(emissionsQuery.data?.avgRate / 1000).toFixed(
                        0,
                    )} kg/h`}
                    label={"Average"}
                />
            </DashboardStatsGroup>
            <DashboardStatsGroup title="Event Status">
                <DashboardMetric
                    value={emissionsQuery.data?._new}
                    label={"New"}
                />
                <DashboardMetric
                    value={eventsQuery.data?.inProgress}
                    label={"In progress"}
                />
                <DashboardMetric
                    value={eventsQuery.data?.complete}
                    label={"Completed"}
                />
            </DashboardStatsGroup>
        </div>
    );
};

export const ThirdPartyEmissionDashboard = () => {
    return (
        <div>
            <DashboardCard className="border-t-4 border-t-ae-emission-3rd-party">
                <StatsPanel />
            </DashboardCard>
            <EmissionAndEventManagement
                dataSource={["THIRD_PARTY"]}
                tabClassnames="border-t-2 border-t-ae-emission-3rd-party"
                eventModalTitle="3rd Party Detection"
            />
        </div>
    );
};
