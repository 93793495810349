/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedDataPointStatsList,
  PaginatedOperatorStatsList,
} from '../models/index';
import {
    PaginatedDataPointStatsListFromJSON,
    PaginatedDataPointStatsListToJSON,
    PaginatedOperatorStatsListFromJSON,
    PaginatedOperatorStatsListToJSON,
} from '../models/index';

export interface AdminDataPointsStatsListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
}

export interface AdminEmissionsRecordsStatsListRequest {
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    dataSource?: Array<AdminEmissionsRecordsStatsListDataSourceEnum>;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionStatus?: Array<AdminEmissionsRecordsStatsListEmissionStatusEnum>;
    eventStatus?: Array<AdminEmissionsRecordsStatsListEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    secondaryDataSource?: string;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Returns statistics for data points.
     */
    async adminDataPointsStatsListRaw(requestParameters: AdminDataPointsStatsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDataPointStatsList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/data-points/stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDataPointStatsListFromJSON(jsonValue));
    }

    /**
     * Returns statistics for data points.
     */
    async adminDataPointsStatsList(requestParameters: AdminDataPointsStatsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDataPointStatsList> {
        const response = await this.adminDataPointsStatsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns statistics for data points.
     */
    async adminEmissionsRecordsStatsListRaw(requestParameters: AdminEmissionsRecordsStatsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOperatorStatsList>> {
        const queryParameters: any = {};

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/emissions-records/stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOperatorStatsListFromJSON(jsonValue));
    }

    /**
     * Returns statistics for data points.
     */
    async adminEmissionsRecordsStatsList(requestParameters: AdminEmissionsRecordsStatsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOperatorStatsList> {
        const response = await this.adminEmissionsRecordsStatsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AdminEmissionsRecordsStatsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type AdminEmissionsRecordsStatsListDataSourceEnum = typeof AdminEmissionsRecordsStatsListDataSourceEnum[keyof typeof AdminEmissionsRecordsStatsListDataSourceEnum];
/**
 * @export
 */
export const AdminEmissionsRecordsStatsListEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type AdminEmissionsRecordsStatsListEmissionStatusEnum = typeof AdminEmissionsRecordsStatsListEmissionStatusEnum[keyof typeof AdminEmissionsRecordsStatsListEmissionStatusEnum];
/**
 * @export
 */
export const AdminEmissionsRecordsStatsListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type AdminEmissionsRecordsStatsListEventStatusEnum = typeof AdminEmissionsRecordsStatsListEventStatusEnum[keyof typeof AdminEmissionsRecordsStatsListEventStatusEnum];
