import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, ReactNode } from "react";

interface ModalProps {
    visible: boolean;
    onClose?: () => void;
    children: ReactNode;
    size: "sm" | "lg";
}

export const Modal = (props: ModalProps) => {
    return (
        <Transition appear show={props.visible} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-50"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-50"
                        >
                            <Dialog.Panel
                                className={`
                                    ${
                                        props.size == "sm"
                                            ? "w-[512px]"
                                            : "w-3/4"
                                    }
                                    p-6 transform rounded bg-white text-left align-middle transition-all
                                `}
                            >
                                <button
                                    type="button"
                                    className="absolute right-6 top-6 p-2 rounded border border-transparent text-sm font-medium hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={props.onClose}
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                                {props.children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

interface FullscreenModalProps {
    title?: string;
    visible: boolean;
    onClose?: () => void;
    children: ReactNode;
}

export const FullscreenModal = (props: FullscreenModalProps) => {
    return (
        <Transition appear show={props.visible} as={Fragment}>
            <Dialog as="div" onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-50"
                >
                    <Dialog.Panel className="fixed inset-0 w-screen h-screen bg-ae-gray-100 overflow-auto">
                        <div className="relative container mx-auto max-w-[1200px] w-full h-full py-8">
                            <div className="mt-6 flex items-center justify-between">
                                <h1 className="text-stone-900 text-xl font-semibold">
                                    {props.title}
                                </h1>
                                <button
                                    type="button"
                                    className="p-2 rounded border border-transparent text-sm font-medium hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={props.onClose}
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>
                            {props.children}
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
};
