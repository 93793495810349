export const generateIconAtlas = (
    color: [number, number, number],
    ignoreContrast?: boolean,
) => {
    // Compute color luminance
    const luminance =
        0.2126 * (color[0] / 255.0) +
        0.7152 * (color[1] / 255.0) +
        0.0722 * (color[2] / 255.0);
    // Determine the most contrasting bg color for the shadow
    const bgColor =
        !ignoreContrast && luminance > 0.5
            ? [0, 0, 0, 0.4]
            : [255, 255, 255, 0.7];
    // Render icon
    const crossHairIcon = `
        <svg width="48" height="48" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="8" fill="rgb(${bgColor.join(",")})" />
            <path
                fill-rule="evenodd"
                stroke-width="1"
                clip-rule="evenodd"
                fill="rgb(${color.join(",")})"
                d="M9 0a1 1 0 0 1 1 1v1.07A7 7 0 0 1 15.93 8H17a1 1 0 1 1 0 2h-1.07A7 7 0 0 1 10 15.93V17a1 1 0 1 1-2 0v-1.07A7 7 0 0 1 2.07 10H1a1 1 0 1 1 0-2h1.07A7.005 7.005 0 0 1 8 2.07V1a1 1 0 0 1 1-1m4.9 8H13a1 1 0 1 0 0 2h.9a5.01 5.01 0 0 1-3.9 3.9V13a1 1 0 1 0-2 0v.9A5.01 5.01 0 0 1 4.1 10H5a1 1 0 1 0 0-2h-.9A5.01 5.01 0 0 1 8 4.1V5a1 1 0 1 0 2 0v-.9A5.01 5.01 0 0 1 13.9 8"
            />
        </svg>
    `;
    return `data:image/svg+xml;base64,${btoa(crossHairIcon)}`;
};
