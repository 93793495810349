/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConflictMatchingStatusEnum } from './ConflictMatchingStatusEnum';
import {
    ConflictMatchingStatusEnumFromJSON,
    ConflictMatchingStatusEnumFromJSONTyped,
    ConflictMatchingStatusEnumToJSON,
} from './ConflictMatchingStatusEnum';
import type { CsvDataSridEnum } from './CsvDataSridEnum';
import {
    CsvDataSridEnumFromJSON,
    CsvDataSridEnumFromJSONTyped,
    CsvDataSridEnumToJSON,
} from './CsvDataSridEnum';
import type { DeduplicationStatusEnum } from './DeduplicationStatusEnum';
import {
    DeduplicationStatusEnumFromJSON,
    DeduplicationStatusEnumFromJSONTyped,
    DeduplicationStatusEnumToJSON,
} from './DeduplicationStatusEnum';
import type { ImportStatusEnum } from './ImportStatusEnum';
import {
    ImportStatusEnumFromJSON,
    ImportStatusEnumFromJSONTyped,
    ImportStatusEnumToJSON,
} from './ImportStatusEnum';
import type { MatchingStatusEnum } from './MatchingStatusEnum';
import {
    MatchingStatusEnumFromJSON,
    MatchingStatusEnumFromJSONTyped,
    MatchingStatusEnumToJSON,
} from './MatchingStatusEnum';

/**
 * Extends `InfrastructureImportSerializer` to add admin only fields
 * @export
 * @interface AdminInfrastructureImport
 */
export interface AdminInfrastructureImport {
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImport
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    owner: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImport
     */
    readonly ownerName: string;
    /**
     * 
     * @type {ImportStatusEnum}
     * @memberof AdminInfrastructureImport
     */
    readonly importStatus: ImportStatusEnum;
    /**
     * 
     * @type {CsvDataSridEnum}
     * @memberof AdminInfrastructureImport
     */
    csvDataSrid?: CsvDataSridEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImport
     */
    csvInputFilename: string;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    readonly createdBy: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImport
     */
    readonly createdByName: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminInfrastructureImport
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminInfrastructureImport
     */
    readonly updatedAt: Date;
    /**
     * Computes count of children items.
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    readonly itemCount: number;
    /**
     * 
     * @type {MatchingStatusEnum}
     * @memberof AdminInfrastructureImport
     */
    matchingStatus?: MatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    exactMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    nearMatchDistance?: number;
    /**
     * 
     * @type {ConflictMatchingStatusEnum}
     * @memberof AdminInfrastructureImport
     */
    conflictMatchingStatus?: ConflictMatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    crossOperatorConflictDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    crossOperatorNearMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInfrastructureImport
     */
    similarityCheckerCentroidDistance?: number;
    /**
     * 
     * @type {DeduplicationStatusEnum}
     * @memberof AdminInfrastructureImport
     */
    readonly deduplicationStatus: DeduplicationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminInfrastructureImport
     */
    csvConflictsFile: string;
    /**
     * 
     * @type {any}
     * @memberof AdminInfrastructureImport
     */
    readonly errors: any | null;
}

/**
 * Check if a given object implements the AdminInfrastructureImport interface.
 */
export function instanceOfAdminInfrastructureImport(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('ownerName' in value)) return false;
    if (!('importStatus' in value)) return false;
    if (!('csvInputFilename' in value)) return false;
    if (!('createdBy' in value)) return false;
    if (!('createdByName' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('itemCount' in value)) return false;
    if (!('deduplicationStatus' in value)) return false;
    if (!('csvConflictsFile' in value)) return false;
    if (!('errors' in value)) return false;
    return true;
}

export function AdminInfrastructureImportFromJSON(json: any): AdminInfrastructureImport {
    return AdminInfrastructureImportFromJSONTyped(json, false);
}

export function AdminInfrastructureImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInfrastructureImport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'ownerName': json['owner_name'],
        'importStatus': ImportStatusEnumFromJSON(json['import_status']),
        'csvDataSrid': json['csv_data_srid'] == null ? undefined : CsvDataSridEnumFromJSON(json['csv_data_srid']),
        'csvInputFilename': json['csv_input_filename'],
        'createdBy': json['created_by'],
        'createdByName': json['created_by_name'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'itemCount': json['item_count'],
        'matchingStatus': json['matching_status'] == null ? undefined : MatchingStatusEnumFromJSON(json['matching_status']),
        'exactMatchDistance': json['exact_match_distance'] == null ? undefined : json['exact_match_distance'],
        'nearMatchDistance': json['near_match_distance'] == null ? undefined : json['near_match_distance'],
        'conflictMatchingStatus': json['conflict_matching_status'] == null ? undefined : ConflictMatchingStatusEnumFromJSON(json['conflict_matching_status']),
        'crossOperatorConflictDistance': json['cross_operator_conflict_distance'] == null ? undefined : json['cross_operator_conflict_distance'],
        'crossOperatorNearMatchDistance': json['cross_operator_near_match_distance'] == null ? undefined : json['cross_operator_near_match_distance'],
        'similarityCheckerCentroidDistance': json['similarity_checker_centroid_distance'] == null ? undefined : json['similarity_checker_centroid_distance'],
        'deduplicationStatus': DeduplicationStatusEnumFromJSON(json['deduplication_status']),
        'csvConflictsFile': json['csv_conflicts_file'],
        'errors': json['errors'],
    };
}

export function AdminInfrastructureImportToJSON(value?: Omit<AdminInfrastructureImport, 'id'|'owner_name'|'import_status'|'created_by'|'created_by_name'|'created_at'|'updated_at'|'item_count'|'deduplication_status'|'errors'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'csv_data_srid': CsvDataSridEnumToJSON(value['csvDataSrid']),
        'csv_input_filename': value['csvInputFilename'],
        'matching_status': MatchingStatusEnumToJSON(value['matchingStatus']),
        'exact_match_distance': value['exactMatchDistance'],
        'near_match_distance': value['nearMatchDistance'],
        'conflict_matching_status': ConflictMatchingStatusEnumToJSON(value['conflictMatchingStatus']),
        'cross_operator_conflict_distance': value['crossOperatorConflictDistance'],
        'cross_operator_near_match_distance': value['crossOperatorNearMatchDistance'],
        'similarity_checker_centroid_distance': value['similarityCheckerCentroidDistance'],
        'csv_conflicts_file': value['csvConflictsFile'],
    };
}

