import { useEffect, useMemo, useState } from "react";
import {
    EmissionRecordView,
    Infrastructure,
    MapAerialImagesListRequest,
    SimpleInfrastructure,
} from "../../apiClient/generated";
import { useInfrastructureApiClient, useMapApiClient } from "../../hooks";
import { MiniMapBase } from "../Map/MiniMap";
import { InfrastructureLayer } from "../Map/components/layers/Infrastructure/infrastructure";
import { AerialImagesLayer } from "../MapV2/layers/infrastructure";
import * as turf from "@turf/turf";
import { GeoJsonLayer } from "@deck.gl/layers";
import { createFeatureCollection } from "../../utils/geopatialUtils";
import {
    EmissionImagesLayer,
    PlumeOutlinesLayer,
} from "../MapV2/layers/emissions";
import { AerialImagesList } from "../../apiClient/generated";

interface EmissionRecordPreviewMap {
    infrastructure?: SimpleInfrastructure[];
    emissionRecords: EmissionRecordView[];
    onClick?: (infrastructure?: SimpleInfrastructure) => void;
}

/**
 * Fetches aerial images for the given infrastructure and emission records
 */
const useAerialImages = (
    emissionRecords: EmissionRecordView[],
    infrastructure?: SimpleInfrastructure[],
) => {
    const [aerialImages, setAerialImages] = useState<AerialImagesList[]>([]);
    const mapApiClient = useMapApiClient();
    useEffect(() => {
        const fetchData = async () => {
            const filter: MapAerialImagesListRequest = {};
            setAerialImages([]);
            const detectionDates = emissionRecords
                .map((record) => record.dataPoint.detectionTimestamp)
                .sort();
            if (detectionDates.length > 0) {
                const takenAtAfter = new Date(detectionDates[0]);
                const takenAtBefore = new Date(
                    detectionDates[detectionDates.length - 1],
                );

                takenAtAfter.setDate(takenAtAfter.getDate() - 10);
                takenAtBefore.setDate(takenAtBefore.getDate() + 10);

                filter.takenAtDataRangeBefore = takenAtBefore;
                filter.takenAtDataRangeAfter = takenAtAfter;
            }
            if (infrastructure && infrastructure.length > 0) {
                filter.infrastructure = infrastructure.map((i) => i.id);
                const response = await mapApiClient.mapAerialImagesList(filter);
                setAerialImages(response.results);
            }
        };
        fetchData();
    }, [infrastructure, emissionRecords, mapApiClient]);

    return aerialImages;
};

export const EmissionRecordPreviewMap = (props: EmissionRecordPreviewMap) => {
    const apiClient = useInfrastructureApiClient();
    const [infrastructure, setInfrastructure] = useState<Infrastructure[]>([]);

    // Compute minimap center
    const mapCenter = useMemo(() => {
        if (props.infrastructure.length === 0) {
            return undefined;
        }

        return turf.center(
            turf.points(
                props.infrastructure.map((i) => i.location.coordinates),
            ),
        );
    }, [props.infrastructure]);

    const aerialImages = useAerialImages(
        props.emissionRecords,
        props.infrastructure,
    );

    useEffect(() => {
        const fetchData = async () => {
            setInfrastructure([]);
            let infrastructureData = [];
            for (const infra of props.infrastructure) {
                const response = await apiClient.infrastructureListList({
                    parent: infra.id,
                    pageSize: 50,
                });
                infrastructureData = infrastructureData.concat(
                    response.results,
                );
            }
            setInfrastructure(infrastructureData);
        };
        fetchData();
    }, [props.infrastructure, apiClient]);

    const emissionKey =
        props.emissionRecords.map((i) => i.id).join("-") || "empty";

    const plumeImages = props.emissionRecords
        .filter((record) => record.dataPoint.plumeImage?.processed)
        .map((record) => record.dataPoint.plumeImage);
    const plumeOutlines = props.emissionRecords
        .filter((record) => record.dataPoint.geometry !== null)
        .map((record) => record.dataPoint.geometry);

    return (
        <div
            key={emissionKey}
            className="relative h-[630px] overflow-hidden rounded-lg"
        >
            <MiniMapBase
                onClick={props.onClick}
                initialLatitude={mapCenter?.geometry.coordinates[0]}
                initialLongitude={mapCenter?.geometry.coordinates[1]}
                initialZoom={12}
                mapLayers={[
                    AerialImagesLayer(aerialImages, true, 13),
                    PlumeOutlinesLayer(
                        plumeOutlines,
                        "plume_outlines",
                        true,
                        12,
                        0.4,
                    ),
                    InfrastructureLayer({
                        data: infrastructure,
                        enabledLayers: ["infrastructure"],
                    }),
                    new GeoJsonLayer({
                        id: emissionKey,
                        data: createFeatureCollection(
                            props.emissionRecords.map(
                                (record) => record.dataPoint,
                            ),
                        ),
                        pointType: "circle",
                        filled: true,
                        visible: true,
                        getPointRadius: 5,
                        pointRadiusUnits: "pixels",
                        lineWidthUnits: "pixels",
                        pickable: true,
                        getFillColor: [255, 0, 0, 255],
                        autoHighlight: true,
                        getLineWidth: 1,
                        getLineColor: [0, 0, 0, 255],
                    }),
                    EmissionImagesLayer(plumeImages, "plumes", true, 12, 0.5),
                ]}
                getTooltip={(info) => {
                    if (!info.object) {
                        return;
                    }
                    if ("equipmentType" in info.object.properties) {
                        return `${info.object.properties.name} (${info.object.properties.siteName})`;
                    }
                    return "Emission";
                }}
            />
        </div>
    );
};
