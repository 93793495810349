import { ReactNode } from "react";

interface ButtonProps {
    children: string | ReactNode;
    variant?: "sm" | "lg";
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const PrimaryButton = (props: ButtonProps) => (
    <button
        onClick={props.onClick}
        className={`
            rounded justify-center items-center
            ${props.variant === "sm" ? "h-7 px-2 py-1" : "h-9 px-4 py-1.5 "}
            ${
                props.disabled
                    ? "bg-gray-300"
                    : "bg-ae-blue-900 hover:bg-ae-blue-500"
            }
        `}
        disabled={props.disabled}
    >
        <div className="text-white text-sm font-medium flex items-center gap-2 whitespace-nowrap">
            {props.children}
        </div>
    </button>
);

export const SecondaryButton = (props: ButtonProps) => (
    <button
        onClick={!props.disabled && props.onClick}
        className={`
            px-4 text-sm text-black
            bg-white border border-ae-gray-250 rounded justify-center items-center
            ${props.variant === "sm" ? "h-7 py-1" : "h-9 py-1.5 "}
            ${
                props.disabled
                    ? "bg-gray-300 cursor-default"
                    : "hover:border-ae-blue-400"
            }
        `}
    >
        <div className="text-ae-blue-900 text-sm font-medium flex items-center gap-2">
            {props.children}
        </div>
    </button>
);

export const PillButton = (props: ButtonProps) => (
    <button
        onClick={props.onClick}
        className="px-1 py-0.5 bg-white border border-ae-blue-900 rounded justify-center items-center hover:bg-ae-gray-200"
    >
        <div className="text-ae-blue-900 text-sm font-medium flex items-center gap-2">
            {props.children}
        </div>
    </button>
);
