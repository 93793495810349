import {
    faUpload,
    faArrowsRotate,
    faChevronUp,
    faChevronDown,
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchInput } from "../ui/Inputs";
import { useMemo, useState } from "react";
import { useQuery, useQueries } from "@tanstack/react-query";
import { useGeoDataApi } from "../hooks";
import { FilterGroup, FilterItem } from "../apiClient/generated";
import { Disclosure } from "@headlessui/react";
import { readGeoFileAsGeoJson } from "../utils/geopatialUtils";
import { multiPolygon } from "@turf/helpers";

const LoadingIcon = () => (
    <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faArrowsRotate} className="w-4 animate-spin" />
    </div>
);

interface CheckboxItemProps {
    item: FilterItem;
    selected?: number[];
    onSelect: (selected: number[]) => void;
}

const CheckboxItem = ({ item, selected, onSelect }: CheckboxItemProps) => {
    const isChecked = selected?.some((i) => item.id === i);
    const handleClick = () => {
        if (isChecked) {
            onSelect(selected.filter((i) => i !== item.id));
        } else {
            onSelect([...(selected || []), item.id]);
        }
    };

    return (
        <label
            className="flex items-center justify-start gap-2"
            key={item.name}
        >
            <input
                type="checkbox"
                className="rounded"
                defaultChecked={isChecked}
                onClick={handleClick}
            />
            <span>{item.name}</span>
        </label>
    );
};

interface GeoFilterItemsProps {
    group: FilterGroup;
    items?: FilterItem[];
    selected?: number[];
    onSelect: (selection: number[]) => void;
    search?: string;
}

export const GeoFilterItems = (props: GeoFilterItemsProps) => {
    const { selected, search, items } = props;
    const filterItems = useMemo(() => {
        if (!props.items) {
            return [];
        }

        return items
            .filter(
                (item) =>
                    // Don't show items not included in search
                    item.name.toLowerCase().includes(search) &&
                    // Or items selected, those appear at the top,
                    // outside groups.
                    !(selected || []).includes(item.id),
            )
            .toSorted((a, b) => {
                if ((selected || []).includes(a.id)) {
                    return 0;
                } else if ((selected || []).includes(b.id)) {
                    return 1;
                }
                return a.name.localeCompare(b.name);
            });
    }, [items, selected, search]);

    return (
        <div>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="w-full font-semibold my-1 p-2 flex justify-between items-center bg-slate-50 hover:bg-slate-100 rounded-md">
                            {props.group.name}
                            <FontAwesomeIcon
                                icon={open ? faChevronUp : faChevronDown}
                                className="w-4"
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="max-h-44 overflow-scroll px-2">
                            <div className="flex flex-col gap-1">
                                {filterItems.map((item) => (
                                    <CheckboxItem
                                        key={item.id}
                                        item={item}
                                        onSelect={props.onSelect}
                                        selected={props.selected}
                                    />
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

interface GeoFilterSelectProps {
    customShape?: string;
    selected?: number[];
    onSelect: (selection: number[]) => void;
    setCustomShape?: (shape?: string) => void;
}

export const GeoFilterSelect = (props: GeoFilterSelectProps) => {
    const { selected } = props;
    const [search, setSearch] = useState("");
    const apiClient = useGeoDataApi();

    const setLocationFilter = (files: FileList) => {
        // Load shape from upload
        readGeoFileAsGeoJson({
            file: files[0],
            callback: (data, error) => {
                if (error) {
                    alert("Failed to load polygon.");
                    return;
                }
                // Extracts all polygons from FeatureCollection
                // and creates MultiPolygon for backend filtering.
                const polygons = [];
                data.features.forEach((f) => {
                    if (f.geometry.type == "Polygon") {
                        polygons.push(f.geometry.coordinates);
                    } else if (f.geometry.type == "MultiPolygon") {
                        f.geometry.coordinates.forEach((p) => polygons.push(p));
                    }
                });

                // If empty, don't filter
                if (polygons.length < 1) {
                    alert("No polygons found inside file.");
                }

                // Create polygon and filter
                const filterPolygon = multiPolygon(polygons);
                props.setCustomShape(JSON.stringify(filterPolygon.geometry));
            },
        });
    };

    // Query filter groups
    const groupsQuery = useQuery({
        queryKey: ["geoFilterGroups"],
        queryFn: async () => {
            const response = await apiClient.geodataGroupsList({
                pageSize: 10,
            });
            return response.results;
        },
        staleTime: Infinity,
    });

    // Second set of queries to get details for each group
    const groupDetailsQueries = useQueries({
        queries:
            groupsQuery.data?.map((group) => ({
                queryKey: ["filterItem", group.id],
                queryFn: async () => {
                    const response = await apiClient.geodataItemsList({
                        pageSize: 200,
                        group: group.id,
                    });
                    return response.results;
                },
                staleTime: Infinity,
            })) || [],
    });

    const selectedItems = useMemo(() => {
        return [
            ...groupDetailsQueries.map((query) => {
                return query.data?.filter((item) =>
                    (selected || []).includes(item.id),
                );
            }),
        ].flat();
    }, [groupDetailsQueries, selected]);

    const isLoading =
        groupsQuery.isLoading ||
        !groupsQuery.data ||
        groupDetailsQueries
            .map((q) => q.isLoading || q.data === undefined)
            .some((v) => v);

    return (
        <div>
            <div className="flex gap-2">
                <SearchInput
                    containerClassName="w-full"
                    value={search}
                    onChange={setSearch}
                    placeholder="Search"
                />
                {props.setCustomShape && (
                    <>
                        <label
                            htmlFor="locationFilter"
                            title="Use a KML/GeoJSON"
                            className="border rounded border-ae-blue-900 px-4 flex items-center justify-center cursor-pointer hover:bg-ae-slate-300"
                        >
                            <FontAwesomeIcon icon={faUpload} className="w-4" />
                        </label>
                        <input
                            id="locationFilter"
                            type="file"
                            className="hidden"
                            onChange={(e) => setLocationFilter(e.target.files)}
                            accept={".geojson,.kml"}
                        />
                    </>
                )}
            </div>
            <hr className="my-2" />
            {props.customShape ? (
                <div className="p-1 font-normal text-sm text-ae-blue-900">
                    <p>Filtering using GeoJSON/KML filter.</p>
                    <hr className="my-1" />
                    <button
                        className="w-full flex justify-center font-bold"
                        onClick={() => props.setCustomShape(undefined)}
                    >
                        Clear filter
                    </button>
                </div>
            ) : (
                <>
                    {isLoading && <LoadingIcon />}
                    {!isLoading && props.selected && (
                        <div className="px-2 py-1">
                            {selectedItems.map((item) => (
                                <CheckboxItem
                                    item={item}
                                    onSelect={props.onSelect}
                                    selected={props.selected}
                                />
                            ))}
                        </div>
                    )}
                    {!isLoading &&
                        groupsQuery.data
                            .toSorted()
                            .map((group, index) => (
                                <GeoFilterItems
                                    group={group}
                                    items={groupDetailsQueries[index].data}
                                    search={search.toLowerCase()}
                                    selected={props.selected}
                                    onSelect={props.onSelect}
                                />
                            ))}
                </>
            )}
        </div>
    );
};
