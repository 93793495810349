import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

interface Props {
    loading: boolean;
    successMessage: boolean;
    onboarding: boolean;
    email?: string;
    sendRequest: (email: string) => void;
}

export const EmailForm = (props: Props) => {
    const [email, setEmail] = useState(props.email || "");

    return (
        <>
            {props.onboarding ? (
                <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Welcome to AERSHED!
                </h2>
            ) : (
                <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Reset password
                </h2>
            )}

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                disabled={props.loading}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        props.sendRequest(email);
                                    }
                                }}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={props.loading}
                            onClick={() => props.sendRequest(email)}
                            className="flex w-full justify-center rounded-md transition-all duration-800 bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-700"
                        >
                            {props.loading ? (
                                <ArrowPathIcon className="animate-spin h-6" />
                            ) : props.onboarding ? (
                                "Get started"
                            ) : (
                                "Reset password"
                            )}
                        </button>
                    </div>

                    {props.successMessage && (
                        <div className="px-4 py-2 bg-green-500 rounded-lg text-white">
                            If the email address is registered, you will receive
                            an email with instructions to reset your password.
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
