/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EventStatusEnum } from './EventStatusEnum';
import {
    EventStatusEnumFromJSON,
    EventStatusEnumFromJSONTyped,
    EventStatusEnumToJSON,
} from './EventStatusEnum';
import type { RootCause } from './RootCause';
import {
    RootCauseFromJSON,
    RootCauseFromJSONTyped,
    RootCauseToJSON,
} from './RootCause';
import type { SimpleEmission } from './SimpleEmission';
import {
    SimpleEmissionFromJSON,
    SimpleEmissionFromJSONTyped,
    SimpleEmissionToJSON,
} from './SimpleEmission';

/**
 * Emission Event serializer with emission records.
 * @export
 * @interface EmissionEventFull
 */
export interface EmissionEventFull {
    /**
     * 
     * @type {string}
     * @memberof EmissionEventFull
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionEventFull
     */
    owner: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionEventFull
     */
    readonly ownerName: string;
    /**
     * 
     * @type {number}
     * @memberof EmissionEventFull
     */
    responsible?: number;
    /**
     * 
     * @type {string}
     * @memberof EmissionEventFull
     */
    readonly responsibleName: string;
    /**
     * 
     * @type {RootCause}
     * @memberof EmissionEventFull
     */
    rootCause: RootCause;
    /**
     * 
     * @type {SimpleEmission}
     * @memberof EmissionEventFull
     */
    mainEmissionRecord: SimpleEmission;
    /**
     * 
     * @type {Array<SimpleEmission>}
     * @memberof EmissionEventFull
     */
    emissionRecords: Array<SimpleEmission>;
    /**
     * 
     * @type {string}
     * @memberof EmissionEventFull
     */
    description?: string;
    /**
     * 
     * @type {EventStatusEnum}
     * @memberof EmissionEventFull
     */
    eventStatus: EventStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventFull
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventFull
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmissionEventFull
     */
    notes?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventFull
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmissionEventFull
     */
    readonly updatedAt: Date;
}

/**
 * Check if a given object implements the EmissionEventFull interface.
 */
export function instanceOfEmissionEventFull(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('ownerName' in value)) return false;
    if (!('responsibleName' in value)) return false;
    if (!('rootCause' in value)) return false;
    if (!('mainEmissionRecord' in value)) return false;
    if (!('emissionRecords' in value)) return false;
    if (!('eventStatus' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EmissionEventFullFromJSON(json: any): EmissionEventFull {
    return EmissionEventFullFromJSONTyped(json, false);
}

export function EmissionEventFullFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionEventFull {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'ownerName': json['owner_name'],
        'responsible': json['responsible'] == null ? undefined : json['responsible'],
        'responsibleName': json['responsible_name'],
        'rootCause': RootCauseFromJSON(json['root_cause']),
        'mainEmissionRecord': SimpleEmissionFromJSON(json['main_emission_record']),
        'emissionRecords': ((json['emission_records'] as Array<any>).map(SimpleEmissionFromJSON)),
        'description': json['description'] == null ? undefined : json['description'],
        'eventStatus': EventStatusEnumFromJSON(json['event_status']),
        'startDate': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'endDate': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'notes': json['notes'] == null ? undefined : json['notes'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function EmissionEventFullToJSON(value?: Omit<EmissionEventFull, 'id'|'owner_name'|'responsible_name'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'responsible': value['responsible'],
        'root_cause': RootCauseToJSON(value['rootCause']),
        'main_emission_record': SimpleEmissionToJSON(value['mainEmissionRecord']),
        'emission_records': ((value['emissionRecords'] as Array<any>).map(SimpleEmissionToJSON)),
        'description': value['description'],
        'event_status': EventStatusEnumToJSON(value['eventStatus']),
        'start_date': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString()),
        'end_date': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString()),
        'notes': value['notes'],
    };
}

