/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AerialImagesList,
  DataPointMap,
  EmissionRecordMap,
  GeoJSONFeatureCollection,
  InfrastructureMapList,
  MapDataBoundaries,
  PaginatedAerialImagesListList,
  PaginatedDataPointMapList,
  PaginatedEmissionRecordMapList,
  PaginatedInfrastructureMapListList,
  PaginatedPipelineMapListList,
  PaginatedPlumeImageList,
  PipelineMapList,
  PlumeImage,
} from '../models/index';
import {
    AerialImagesListFromJSON,
    AerialImagesListToJSON,
    DataPointMapFromJSON,
    DataPointMapToJSON,
    EmissionRecordMapFromJSON,
    EmissionRecordMapToJSON,
    GeoJSONFeatureCollectionFromJSON,
    GeoJSONFeatureCollectionToJSON,
    InfrastructureMapListFromJSON,
    InfrastructureMapListToJSON,
    MapDataBoundariesFromJSON,
    MapDataBoundariesToJSON,
    PaginatedAerialImagesListListFromJSON,
    PaginatedAerialImagesListListToJSON,
    PaginatedDataPointMapListFromJSON,
    PaginatedDataPointMapListToJSON,
    PaginatedEmissionRecordMapListFromJSON,
    PaginatedEmissionRecordMapListToJSON,
    PaginatedInfrastructureMapListListFromJSON,
    PaginatedInfrastructureMapListListToJSON,
    PaginatedPipelineMapListListFromJSON,
    PaginatedPipelineMapListListToJSON,
    PaginatedPlumeImageListFromJSON,
    PaginatedPlumeImageListToJSON,
    PipelineMapListFromJSON,
    PipelineMapListToJSON,
    PlumeImageFromJSON,
    PlumeImageToJSON,
} from '../models/index';

export interface MapAerialImagesListRequest {
    cursor?: string;
    infrastructure?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    takenAtDataRangeAfter?: Date;
    takenAtDataRangeBefore?: Date;
}

export interface MapAerialImagesRetrieveRequest {
    id: string;
}

export interface MapEmissionRecordsListRequest {
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    cursor?: string;
    dataSource?: Array<MapEmissionRecordsListDataSourceEnum>;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    emissionStatus?: Array<MapEmissionRecordsListEmissionStatusEnum>;
    eventStatus?: Array<MapEmissionRecordsListEventStatusEnum>;
    hasEvents?: boolean;
    infrastructure?: string;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    plume?: boolean;
    provider?: Array<string>;
    relatedEvent?: string;
    secondaryDataSource?: string;
}

export interface MapEmissionRecordsRetrieveRequest {
    id: string;
}

export interface MapInfrastructureListRequest {
    cursor?: string;
    infraType?: Array<MapInfrastructureListInfraTypeEnum>;
    locationWithin?: string;
    nearEmission?: string;
    ordering?: string;
    parent?: string;
    pipelineCommodity?: Array<MapInfrastructureListPipelineCommodityEnum>;
    pipelineDiameterMax?: number;
    pipelineDiameterMin?: number;
    pipelineSegmentType?: Array<MapInfrastructureListPipelineSegmentTypeEnum>;
    pipelineSizeMax?: number;
    pipelineSizeMin?: number;
}

export interface MapInfrastructureOverviewsRetrieveRequest {
    boxSize?: number;
    infraType?: Array<MapInfrastructureOverviewsRetrieveInfraTypeEnum>;
    locationWithin?: string;
    nearEmission?: string;
    ordering?: string;
    parent?: string;
    pipelineCommodity?: Array<MapInfrastructureOverviewsRetrievePipelineCommodityEnum>;
    pipelineDiameterMax?: number;
    pipelineDiameterMin?: number;
    pipelineSegmentType?: Array<MapInfrastructureOverviewsRetrievePipelineSegmentTypeEnum>;
    pipelineSizeMax?: number;
    pipelineSizeMin?: number;
}

export interface MapInfrastructureRetrieveRequest {
    id: string;
}

export interface MapPipelinesListRequest {
    cursor?: string;
    locationWithin?: string;
    ordering?: string;
    pipelineProduct?: Array<MapPipelinesListPipelineProductEnum>;
    pipelineSizeMax?: number;
    pipelineSizeMin?: number;
    pipelineType?: Array<MapPipelinesListPipelineTypeEnum>;
}

export interface MapPipelinesOverviewsRetrieveRequest {
    locationWithin?: string;
    ordering?: string;
    pipelineProduct?: Array<MapPipelinesOverviewsRetrievePipelineProductEnum>;
    pipelineSizeMax?: number;
    pipelineSizeMin?: number;
    pipelineType?: Array<MapPipelinesOverviewsRetrievePipelineTypeEnum>;
}

export interface MapPipelinesRetrieveRequest {
    id: string;
}

export interface MapPlumeImagesListRequest {
    cursor?: string;
    dataSource?: Array<MapPlumeImagesListDataSourceEnum>;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    eventStatus?: Array<MapPlumeImagesListEventStatusEnum>;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    provider?: Array<string>;
}

export interface MapPlumeImagesRetrieveRequest {
    id: string;
}

export interface MapPublicEmissionsListRequest {
    cursor?: string;
    dataSource?: Array<MapPublicEmissionsListDataSourceEnum>;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    provider?: Array<string>;
}

export interface MapPublicEmissionsRetrieveRequest {
    id: string;
}

export interface MapPublicPlumeImagesListRequest {
    cursor?: string;
    dataSource?: Array<MapPublicPlumeImagesListDataSourceEnum>;
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    eventStatus?: Array<MapPublicPlumeImagesListEventStatusEnum>;
    locationPreset?: Array<string>;
    locationWithin?: string;
    ordering?: string;
    provider?: Array<string>;
}

export interface MapPublicPlumeImagesRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class MapApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve AerialImage information
     */
    async mapAerialImagesListRaw(requestParameters: MapAerialImagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAerialImagesListList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['takenAtDataRangeAfter'] != null) {
            queryParameters['taken_at_data_range_after'] = (requestParameters['takenAtDataRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['takenAtDataRangeBefore'] != null) {
            queryParameters['taken_at_data_range_before'] = (requestParameters['takenAtDataRangeBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/aerial-images/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAerialImagesListListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve AerialImage information
     */
    async mapAerialImagesList(requestParameters: MapAerialImagesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAerialImagesListList> {
        const response = await this.mapAerialImagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve AerialImage information
     */
    async mapAerialImagesRetrieveRaw(requestParameters: MapAerialImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AerialImagesList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapAerialImagesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/aerial-images/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AerialImagesListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve AerialImage information
     */
    async mapAerialImagesRetrieve(requestParameters: MapAerialImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AerialImagesList> {
        const response = await this.mapAerialImagesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emissions information for the map
     */
    async mapEmissionRecordsListRaw(requestParameters: MapEmissionRecordsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEmissionRecordMapList>> {
        const queryParameters: any = {};

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['emissionStatus'] != null) {
            queryParameters['emission_status'] = requestParameters['emissionStatus'];
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['hasEvents'] != null) {
            queryParameters['has_events'] = requestParameters['hasEvents'];
        }

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['plume'] != null) {
            queryParameters['plume'] = requestParameters['plume'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        if (requestParameters['relatedEvent'] != null) {
            queryParameters['related_event'] = requestParameters['relatedEvent'];
        }

        if (requestParameters['secondaryDataSource'] != null) {
            queryParameters['secondary_data_source'] = requestParameters['secondaryDataSource'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/emission-records/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEmissionRecordMapListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emissions information for the map
     */
    async mapEmissionRecordsList(requestParameters: MapEmissionRecordsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEmissionRecordMapList> {
        const response = await this.mapEmissionRecordsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve emissions information for the map
     */
    async mapEmissionRecordsRetrieveRaw(requestParameters: MapEmissionRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionRecordMap>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapEmissionRecordsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/emission-records/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionRecordMapFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve emissions information for the map
     */
    async mapEmissionRecordsRetrieve(requestParameters: MapEmissionRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionRecordMap> {
        const response = await this.mapEmissionRecordsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async mapInfrastructureListRaw(requestParameters: MapInfrastructureListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInfrastructureMapListList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['nearEmission'] != null) {
            queryParameters['near_emission'] = requestParameters['nearEmission'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parent'] != null) {
            queryParameters['parent'] = requestParameters['parent'];
        }

        if (requestParameters['pipelineCommodity'] != null) {
            queryParameters['pipeline_commodity'] = requestParameters['pipelineCommodity'];
        }

        if (requestParameters['pipelineDiameterMax'] != null) {
            queryParameters['pipeline_diameter_max'] = requestParameters['pipelineDiameterMax'];
        }

        if (requestParameters['pipelineDiameterMin'] != null) {
            queryParameters['pipeline_diameter_min'] = requestParameters['pipelineDiameterMin'];
        }

        if (requestParameters['pipelineSegmentType'] != null) {
            queryParameters['pipeline_segment_type'] = requestParameters['pipelineSegmentType'];
        }

        if (requestParameters['pipelineSizeMax'] != null) {
            queryParameters['pipeline_size_max'] = requestParameters['pipelineSizeMax'];
        }

        if (requestParameters['pipelineSizeMin'] != null) {
            queryParameters['pipeline_size_min'] = requestParameters['pipelineSizeMin'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/infrastructure/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInfrastructureMapListListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async mapInfrastructureList(requestParameters: MapInfrastructureListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInfrastructureMapListList> {
        const response = await this.mapInfrastructureListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View that returns the Map boundaries for all infrastructure owned by a user\'s company.
     */
    async mapInfrastructureMapBoundsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapDataBoundaries>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/infrastructure/map_bounds/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapDataBoundariesFromJSON(jsonValue));
    }

    /**
     * View that returns the Map boundaries for all infrastructure owned by a user\'s company.
     */
    async mapInfrastructureMapBoundsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapDataBoundaries> {
        const response = await this.mapInfrastructureMapBoundsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Computes overviews for infrastructure efficiently.
     */
    async mapInfrastructureOverviewsRetrieveRaw(requestParameters: MapInfrastructureOverviewsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeoJSONFeatureCollection>> {
        const queryParameters: any = {};

        if (requestParameters['boxSize'] != null) {
            queryParameters['box_size'] = requestParameters['boxSize'];
        }

        if (requestParameters['infraType'] != null) {
            queryParameters['infra_type'] = requestParameters['infraType'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['nearEmission'] != null) {
            queryParameters['near_emission'] = requestParameters['nearEmission'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['parent'] != null) {
            queryParameters['parent'] = requestParameters['parent'];
        }

        if (requestParameters['pipelineCommodity'] != null) {
            queryParameters['pipeline_commodity'] = requestParameters['pipelineCommodity'];
        }

        if (requestParameters['pipelineDiameterMax'] != null) {
            queryParameters['pipeline_diameter_max'] = requestParameters['pipelineDiameterMax'];
        }

        if (requestParameters['pipelineDiameterMin'] != null) {
            queryParameters['pipeline_diameter_min'] = requestParameters['pipelineDiameterMin'];
        }

        if (requestParameters['pipelineSegmentType'] != null) {
            queryParameters['pipeline_segment_type'] = requestParameters['pipelineSegmentType'];
        }

        if (requestParameters['pipelineSizeMax'] != null) {
            queryParameters['pipeline_size_max'] = requestParameters['pipelineSizeMax'];
        }

        if (requestParameters['pipelineSizeMin'] != null) {
            queryParameters['pipeline_size_min'] = requestParameters['pipelineSizeMin'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/infrastructure/overviews/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeoJSONFeatureCollectionFromJSON(jsonValue));
    }

    /**
     * Computes overviews for infrastructure efficiently.
     */
    async mapInfrastructureOverviewsRetrieve(requestParameters: MapInfrastructureOverviewsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeoJSONFeatureCollection> {
        const response = await this.mapInfrastructureOverviewsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async mapInfrastructureRetrieveRaw(requestParameters: MapInfrastructureRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfrastructureMapList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapInfrastructureRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/infrastructure/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfrastructureMapListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve infrastructure information
     */
    async mapInfrastructureRetrieve(requestParameters: MapInfrastructureRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfrastructureMapList> {
        const response = await this.mapInfrastructureRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async mapPipelinesListRaw(requestParameters: MapPipelinesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPipelineMapListList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['pipelineProduct'] != null) {
            queryParameters['pipeline_product'] = requestParameters['pipelineProduct'];
        }

        if (requestParameters['pipelineSizeMax'] != null) {
            queryParameters['pipeline_size_max'] = requestParameters['pipelineSizeMax'];
        }

        if (requestParameters['pipelineSizeMin'] != null) {
            queryParameters['pipeline_size_min'] = requestParameters['pipelineSizeMin'];
        }

        if (requestParameters['pipelineType'] != null) {
            queryParameters['pipeline_type'] = requestParameters['pipelineType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/pipelines/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPipelineMapListListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async mapPipelinesList(requestParameters: MapPipelinesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPipelineMapListList> {
        const response = await this.mapPipelinesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Computes clusters of pipelines to display in higher zoom levels.
     */
    async mapPipelinesOverviewsRetrieveRaw(requestParameters: MapPipelinesOverviewsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeoJSONFeatureCollection>> {
        const queryParameters: any = {};

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['pipelineProduct'] != null) {
            queryParameters['pipeline_product'] = requestParameters['pipelineProduct'];
        }

        if (requestParameters['pipelineSizeMax'] != null) {
            queryParameters['pipeline_size_max'] = requestParameters['pipelineSizeMax'];
        }

        if (requestParameters['pipelineSizeMin'] != null) {
            queryParameters['pipeline_size_min'] = requestParameters['pipelineSizeMin'];
        }

        if (requestParameters['pipelineType'] != null) {
            queryParameters['pipeline_type'] = requestParameters['pipelineType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/pipelines/overviews/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeoJSONFeatureCollectionFromJSON(jsonValue));
    }

    /**
     * Computes clusters of pipelines to display in higher zoom levels.
     */
    async mapPipelinesOverviewsRetrieve(requestParameters: MapPipelinesOverviewsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeoJSONFeatureCollection> {
        const response = await this.mapPipelinesOverviewsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async mapPipelinesRetrieveRaw(requestParameters: MapPipelinesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineMapList>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapPipelinesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/pipelines/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineMapListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve pipeline information
     */
    async mapPipelinesRetrieve(requestParameters: MapPipelinesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineMapList> {
        const response = await this.mapPipelinesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve plume image information for the map.
     */
    async mapPlumeImagesListRaw(requestParameters: MapPlumeImagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPlumeImageList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/plume-images/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPlumeImageListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve plume image information for the map.
     */
    async mapPlumeImagesList(requestParameters: MapPlumeImagesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPlumeImageList> {
        const response = await this.mapPlumeImagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve plume image information for the map.
     */
    async mapPlumeImagesRetrieveRaw(requestParameters: MapPlumeImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlumeImage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapPlumeImagesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/plume-images/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlumeImageFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve plume image information for the map.
     */
    async mapPlumeImagesRetrieve(requestParameters: MapPlumeImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlumeImage> {
        const response = await this.mapPlumeImagesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async mapPublicEmissionsListRaw(requestParameters: MapPublicEmissionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDataPointMapList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/public-emissions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDataPointMapListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async mapPublicEmissionsList(requestParameters: MapPublicEmissionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDataPointMapList> {
        const response = await this.mapPublicEmissionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async mapPublicEmissionsRetrieveRaw(requestParameters: MapPublicEmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPointMap>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapPublicEmissionsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/public-emissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPointMapFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public data point information for the map.
     */
    async mapPublicEmissionsRetrieve(requestParameters: MapPublicEmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPointMap> {
        const response = await this.mapPublicEmissionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public plume image information for the map.  FIXME: it feels like this one can be a simple filter.
     */
    async mapPublicPlumeImagesListRaw(requestParameters: MapPublicPlumeImagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPlumeImageList>> {
        const queryParameters: any = {};

        if (requestParameters['cursor'] != null) {
            queryParameters['cursor'] = requestParameters['cursor'];
        }

        if (requestParameters['dataSource'] != null) {
            queryParameters['data_source'] = requestParameters['dataSource'];
        }

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['eventStatus'] != null) {
            queryParameters['event_status'] = requestParameters['eventStatus'];
        }

        if (requestParameters['locationPreset'] != null) {
            queryParameters['location_preset'] = requestParameters['locationPreset'];
        }

        if (requestParameters['locationWithin'] != null) {
            queryParameters['location_within'] = requestParameters['locationWithin'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['provider'] != null) {
            queryParameters['provider'] = requestParameters['provider'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/public-plume-images/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPlumeImageListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public plume image information for the map.  FIXME: it feels like this one can be a simple filter.
     */
    async mapPublicPlumeImagesList(requestParameters: MapPublicPlumeImagesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPlumeImageList> {
        const response = await this.mapPublicPlumeImagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve public plume image information for the map.  FIXME: it feels like this one can be a simple filter.
     */
    async mapPublicPlumeImagesRetrieveRaw(requestParameters: MapPublicPlumeImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlumeImage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling mapPublicPlumeImagesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/map/public-plume-images/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlumeImageFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve public plume image information for the map.  FIXME: it feels like this one can be a simple filter.
     */
    async mapPublicPlumeImagesRetrieve(requestParameters: MapPublicPlumeImagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlumeImage> {
        const response = await this.mapPublicPlumeImagesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const MapEmissionRecordsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type MapEmissionRecordsListDataSourceEnum = typeof MapEmissionRecordsListDataSourceEnum[keyof typeof MapEmissionRecordsListDataSourceEnum];
/**
 * @export
 */
export const MapEmissionRecordsListEmissionStatusEnum = {
    Accepted: 'ACCEPTED',
    Matched: 'MATCHED',
    NearMatch: 'NEAR_MATCH',
    Rejected: 'REJECTED'
} as const;
export type MapEmissionRecordsListEmissionStatusEnum = typeof MapEmissionRecordsListEmissionStatusEnum[keyof typeof MapEmissionRecordsListEmissionStatusEnum];
/**
 * @export
 */
export const MapEmissionRecordsListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type MapEmissionRecordsListEventStatusEnum = typeof MapEmissionRecordsListEventStatusEnum[keyof typeof MapEmissionRecordsListEventStatusEnum];
/**
 * @export
 */
export const MapInfrastructureListInfraTypeEnum = {
    Equipment: 'EQUIPMENT',
    EquipmentComponent: 'EQUIPMENT_COMPONENT',
    EquipmentGroup: 'EQUIPMENT_GROUP',
    Pipeline: 'PIPELINE',
    Site: 'SITE'
} as const;
export type MapInfrastructureListInfraTypeEnum = typeof MapInfrastructureListInfraTypeEnum[keyof typeof MapInfrastructureListInfraTypeEnum];
/**
 * @export
 */
export const MapInfrastructureListPipelineCommodityEnum = {
    Gas: 'GAS',
    Hydrogen: 'HYDROGEN',
    Ngl: 'NGL',
    Oil: 'OIL',
    Water: 'WATER'
} as const;
export type MapInfrastructureListPipelineCommodityEnum = typeof MapInfrastructureListPipelineCommodityEnum[keyof typeof MapInfrastructureListPipelineCommodityEnum];
/**
 * @export
 */
export const MapInfrastructureListPipelineSegmentTypeEnum = {
    Disposal: 'DISPOSAL',
    Distribution: 'DISTRIBUTION',
    Gathering: 'GATHERING',
    Transmission: 'TRANSMISSION'
} as const;
export type MapInfrastructureListPipelineSegmentTypeEnum = typeof MapInfrastructureListPipelineSegmentTypeEnum[keyof typeof MapInfrastructureListPipelineSegmentTypeEnum];
/**
 * @export
 */
export const MapInfrastructureOverviewsRetrieveInfraTypeEnum = {
    Equipment: 'EQUIPMENT',
    EquipmentComponent: 'EQUIPMENT_COMPONENT',
    EquipmentGroup: 'EQUIPMENT_GROUP',
    Pipeline: 'PIPELINE',
    Site: 'SITE'
} as const;
export type MapInfrastructureOverviewsRetrieveInfraTypeEnum = typeof MapInfrastructureOverviewsRetrieveInfraTypeEnum[keyof typeof MapInfrastructureOverviewsRetrieveInfraTypeEnum];
/**
 * @export
 */
export const MapInfrastructureOverviewsRetrievePipelineCommodityEnum = {
    Gas: 'GAS',
    Hydrogen: 'HYDROGEN',
    Ngl: 'NGL',
    Oil: 'OIL',
    Water: 'WATER'
} as const;
export type MapInfrastructureOverviewsRetrievePipelineCommodityEnum = typeof MapInfrastructureOverviewsRetrievePipelineCommodityEnum[keyof typeof MapInfrastructureOverviewsRetrievePipelineCommodityEnum];
/**
 * @export
 */
export const MapInfrastructureOverviewsRetrievePipelineSegmentTypeEnum = {
    Disposal: 'DISPOSAL',
    Distribution: 'DISTRIBUTION',
    Gathering: 'GATHERING',
    Transmission: 'TRANSMISSION'
} as const;
export type MapInfrastructureOverviewsRetrievePipelineSegmentTypeEnum = typeof MapInfrastructureOverviewsRetrievePipelineSegmentTypeEnum[keyof typeof MapInfrastructureOverviewsRetrievePipelineSegmentTypeEnum];
/**
 * @export
 */
export const MapPipelinesListPipelineProductEnum = {
    Condensate: 'CONDENSATE',
    NaturalGas: 'NATURAL_GAS',
    Ngl: 'NGL',
    Oil: 'OIL',
    Other: 'OTHER',
    Water: 'WATER'
} as const;
export type MapPipelinesListPipelineProductEnum = typeof MapPipelinesListPipelineProductEnum[keyof typeof MapPipelinesListPipelineProductEnum];
/**
 * @export
 */
export const MapPipelinesListPipelineTypeEnum = {
    Distribution: 'DISTRIBUTION',
    Gathering: 'GATHERING',
    Other: 'OTHER',
    Transmission: 'TRANSMISSION'
} as const;
export type MapPipelinesListPipelineTypeEnum = typeof MapPipelinesListPipelineTypeEnum[keyof typeof MapPipelinesListPipelineTypeEnum];
/**
 * @export
 */
export const MapPipelinesOverviewsRetrievePipelineProductEnum = {
    Condensate: 'CONDENSATE',
    NaturalGas: 'NATURAL_GAS',
    Ngl: 'NGL',
    Oil: 'OIL',
    Other: 'OTHER',
    Water: 'WATER'
} as const;
export type MapPipelinesOverviewsRetrievePipelineProductEnum = typeof MapPipelinesOverviewsRetrievePipelineProductEnum[keyof typeof MapPipelinesOverviewsRetrievePipelineProductEnum];
/**
 * @export
 */
export const MapPipelinesOverviewsRetrievePipelineTypeEnum = {
    Distribution: 'DISTRIBUTION',
    Gathering: 'GATHERING',
    Other: 'OTHER',
    Transmission: 'TRANSMISSION'
} as const;
export type MapPipelinesOverviewsRetrievePipelineTypeEnum = typeof MapPipelinesOverviewsRetrievePipelineTypeEnum[keyof typeof MapPipelinesOverviewsRetrievePipelineTypeEnum];
/**
 * @export
 */
export const MapPlumeImagesListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type MapPlumeImagesListDataSourceEnum = typeof MapPlumeImagesListDataSourceEnum[keyof typeof MapPlumeImagesListDataSourceEnum];
/**
 * @export
 */
export const MapPlumeImagesListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type MapPlumeImagesListEventStatusEnum = typeof MapPlumeImagesListEventStatusEnum[keyof typeof MapPlumeImagesListEventStatusEnum];
/**
 * @export
 */
export const MapPublicEmissionsListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type MapPublicEmissionsListDataSourceEnum = typeof MapPublicEmissionsListDataSourceEnum[keyof typeof MapPublicEmissionsListDataSourceEnum];
/**
 * @export
 */
export const MapPublicPlumeImagesListDataSourceEnum = {
    Epa: 'EPA',
    SelfReported: 'SELF_REPORTED',
    ThirdParty: 'THIRD_PARTY'
} as const;
export type MapPublicPlumeImagesListDataSourceEnum = typeof MapPublicPlumeImagesListDataSourceEnum[keyof typeof MapPublicPlumeImagesListDataSourceEnum];
/**
 * @export
 */
export const MapPublicPlumeImagesListEventStatusEnum = {
    Completed: 'COMPLETED',
    WaitingAction: 'WAITING_ACTION',
    WorkInProgress: 'WORK_IN_PROGRESS'
} as const;
export type MapPublicPlumeImagesListEventStatusEnum = typeof MapPublicPlumeImagesListEventStatusEnum[keyof typeof MapPublicPlumeImagesListEventStatusEnum];
