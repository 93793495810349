import { useQuery } from "@tanstack/react-query";
import { useEmissionRecordsApiClient } from "../../../hooks";
import { SidebarSection, SidebarSkeleton } from "../ui/SidebarItems";
import { EventSidebarDisplay, InfrastructureSidebarDisplay } from "./common";
import { EmissionRecordDisplay } from "../../Emissions/EmissionRecordDisplay";
import { ROUTES } from "../../../routes";
import { EmissioneventsListDataSourceEnum } from "../../../apiClient/generated";
import { useMapData } from "../hooks/mapDataAndFilters";

const EmissionDetails = () => {
    const { selectedContext } = useMapData("mainMap");
    const emissionsApiClient = useEmissionRecordsApiClient();

    // Emission information
    const emissionQuery = useQuery({
        queryKey: ["mapEmissionRecordItem", selectedContext.emissionRecordId],
        queryFn: async () => {
            return await emissionsApiClient.emissionRecordsRetrieve({
                id: selectedContext.emissionRecordId,
            });
        },
        enabled: !!selectedContext.emissionRecordId,
    });

    return (
        <>
            <SidebarSection
                title="Properties"
                loading={emissionQuery.isLoading}
                action={{
                    label: "Manage Emission",
                    fn: () => {
                        let basePage = "";
                        if (
                            emissionQuery.data?.dataPoint.dataSource ===
                            EmissioneventsListDataSourceEnum.ThirdParty
                        ) {
                            basePage = ROUTES.DASHBOARD_THIRD_PARTY;
                        } else if (
                            emissionQuery.data?.dataPoint.dataSource ===
                            EmissioneventsListDataSourceEnum.SelfReported
                        ) {
                            basePage = ROUTES.DASHBOARD_SELF_REPORTED;
                        }
                        window.open(
                            `${basePage}?selectedEvent=${emissionQuery.data?.id}`,
                            "_blank",
                        );
                    },
                }}
            >
                <EmissionRecordDisplay emissionRecord={emissionQuery.data} />
            </SidebarSection>

            {emissionQuery.data && emissionQuery.data.infrastructureData && (
                <SidebarSection
                    title="Infrastructure"
                    loading={emissionQuery.isLoading}
                >
                    <InfrastructureSidebarDisplay
                        infrastructure={emissionQuery.data.infrastructureData}
                    />
                </SidebarSection>
            )}

            {emissionQuery.data && emissionQuery.data.events.length > 0 && (
                <SidebarSection
                    title="Event Management"
                    loading={emissionQuery.isLoading}
                >
                    <EventSidebarDisplay event={emissionQuery.data.events[0]} />
                </SidebarSection>
            )}
        </>
    );
};

export const EmissionDetailSidebar = () => (
    <SidebarSkeleton
        title="Emission"
        tabs={[
            {
                title: "Details",
                content: <EmissionDetails />,
            },
        ]}
    />
);
