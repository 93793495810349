import { DateTime } from "luxon";
import {
    EmissionEvent,
    EmissionEventFull,
    EmissionRecordView,
    Pipeline,
    PipelineSegment,
    SimpleInfrastructure,
} from "../../../apiClient/generated";
import {
    AlignedDataDisplay,
    CoordinatesField,
} from "../../../ui/GenericFields";
import { EventStatusDisplay } from "../../Events/ui/EventStatusDisplay";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

interface EventSidebarDisplayProps {
    event: EmissionEvent | EmissionEventFull;
}

export const EventSidebarDisplay = (props: EventSidebarDisplayProps) => (
    <AlignedDataDisplay
        data={[
            {
                label: "Status",
                value: <EventStatusDisplay status={props.event.eventStatus} />,
            },
            {
                label: "Assignee",
                value: props.event.responsibleName,
            },
            {
                label: "Last update",
                value: DateTime.fromJSDate(
                    props.event.updatedAt,
                ).toLocaleString(DateTime.DATE_MED),
            },
            {
                label: "Created at",
                value: DateTime.fromJSDate(
                    props.event.createdAt,
                ).toLocaleString(DateTime.DATE_MED),
            },
        ]}
    />
);

interface EmissionSidebarDisplayProps {
    emission: EmissionRecordView;
}

export const EmissionSidebarDisplay = (props: EmissionSidebarDisplayProps) => (
    <AlignedDataDisplay
        data={[
            {
                label: "Rate",
                value: (
                    <div>
                        {props.emission.dataPoint.detectedRate / 1000} kg/h
                    </div>
                ),
            },
            {
                label: "Provider",
                value: <div>{props.emission.providerName}</div>,
            },
            {
                label: "Time UTC",
                value: DateTime.fromJSDate(
                    props.emission.dataPoint.detectionTimestamp,
                ).toLocaleString(DateTime.DATETIME_SHORT),
            },
        ]}
    />
);

interface InfrastructureSidebarDisplayProps {
    infrastructure: SimpleInfrastructure;
}

export const InfrastructureSidebarDisplay = (
    props: InfrastructureSidebarDisplayProps,
) => {
    const [expand, setExpand] = useState(false);

    if (!props.infrastructure) {
        return null;
    }

    const data = [
        {
            label: "Name",
            value:
                props.infrastructure.siteName ||
                props.infrastructure.name ||
                props.infrastructure.equipmentType,
        },
        {
            label: "Location",
            value: (
                <CoordinatesField
                    coordinates={props.infrastructure.location?.coordinates}
                    allowCopy
                />
            ),
        },
        {
            label: "Type",
            value: props.infrastructure.infraType.replaceAll("_", " "),
        },
    ];

    if (expand) {
        Object.entries(props.infrastructure.extraData || {})
            .toSorted((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => {
                data.push({
                    label: key,
                    value: value as string,
                });
            });
    }

    return (
        <div>
            <AlignedDataDisplay data={data} />
            <button
                className="ml-1 text-xs underline"
                onClick={() => setExpand(!expand)}
            >
                {expand ? "Hide" : "Show"} details
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className="h-3 w-3 text-black pl-1"
                />
            </button>
        </div>
    );
};

export const PipelineSidebarDisplay = ({ pipeline }: { pipeline: Pipeline }) =>
    pipeline && (
        <AlignedDataDisplay
            data={[
                {
                    label: "Line ID",
                    value: pipeline.lineId,
                },
                {
                    label: "Owner",
                    value: pipeline.ownerName,
                },
                {
                    label: "Product",
                    value: (
                        <span className="capitalize">
                            {pipeline.pipelineProduct
                                .replaceAll("_", " ")
                                .toLocaleLowerCase()}
                        </span>
                    ),
                },
                {
                    label: "Type:",
                    value: (
                        <span className="capitalize">
                            {pipeline.pipelineType
                                .replaceAll("_", " ")
                                .toLocaleLowerCase()}
                        </span>
                    ),
                },
            ]}
        />
    );

export const PipelineSegmentSidebarDisplay = ({
    segment,
}: {
    segment: PipelineSegment;
}) => {
    const [showExtra, setShowExtra] = useState(false);

    if (!segment) {
        return null;
    }

    const data = [
        {
            label: "Material",
            value: segment.material,
        },
        {
            label: "Diameter (inches)",
            value: segment.diameter,
        },
        {
            label: "Above ground",
            value: segment.aboveGround ? "Yes" : "No",
        },
    ];

    return (
        <div className="h-full text-sm">
            <AlignedDataDisplay data={data} />
            {segment.extraData && (
                <button
                    className="p-1.5 my-0.5 rounded hover:bg-ae-slate-200"
                    onClick={() => setShowExtra(!showExtra)}
                >
                    {showExtra
                        ? "Hide extra parameters"
                        : "Show extra parameters"}
                </button>
            )}
            {showExtra && segment.extraData && (
                <AlignedDataDisplay
                    data={Object.entries(segment.extraData).map(
                        ([key, value]) => ({
                            label: key,
                            value: JSON.stringify(value),
                        }),
                    )}
                />
            )}
        </div>
    );
};
