import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

interface CoordinatesFieldProps {
    coordinates?: number[];
    allowCopy?: boolean;
}

export const CoordinatesField = (props: CoordinatesFieldProps) => {
    return (
        <div className="flex items-center">
            {props.coordinates ? (
                <>
                    {props.coordinates[1].toFixed(5)}
                    {", "}
                    {props.coordinates[0].toFixed(5)}
                </>
            ) : (
                "-"
            )}
            {props.allowCopy && (
                <button
                    className="ml-1 hover:text-blue-700 cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                            `${props.coordinates[1]}, ${props.coordinates[0]}`,
                        );
                    }}
                >
                    <ClipboardDocumentIcon className="w-4 h-4" />
                </button>
            )}
        </div>
    );
};

interface AlignedDataDisplayProps {
    data: {
        label: string | ReactNode;
        value: string | ReactNode;
    }[];
}

export const AlignedDataDisplay = (props: AlignedDataDisplayProps) => (
    <table className="w-full text-sm border-separate border-spacing-1">
        <tbody>
            {props.data.map((row, index) => (
                <tr key={index}>
                    <td className="text-ae-slate-900">{row.label}</td>
                    <td className="font-semibold line-clamp-3 break-all w-full">
                        {row.value || "-"}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);
