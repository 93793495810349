/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmissionRecordMapGeometry
 */
export interface EmissionRecordMapGeometry {
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordMapGeometry
     */
    type?: EmissionRecordMapGeometryTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof EmissionRecordMapGeometry
     */
    coordinates?: Array<Array<Array<Array<number>>>>;
}


/**
 * @export
 */
export const EmissionRecordMapGeometryTypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;
export type EmissionRecordMapGeometryTypeEnum = typeof EmissionRecordMapGeometryTypeEnum[keyof typeof EmissionRecordMapGeometryTypeEnum];


/**
 * Check if a given object implements the EmissionRecordMapGeometry interface.
 */
export function instanceOfEmissionRecordMapGeometry(value: object): boolean {
    return true;
}

export function EmissionRecordMapGeometryFromJSON(json: any): EmissionRecordMapGeometry {
    return EmissionRecordMapGeometryFromJSONTyped(json, false);
}

export function EmissionRecordMapGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionRecordMapGeometry {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function EmissionRecordMapGeometryToJSON(value?: EmissionRecordMapGeometry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

