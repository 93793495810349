import { useState } from "react";
import { useInfrastructureApiClient } from "../../hooks";
import {
    AdminInfrastructureImport,
    ImportStatusEnum,
} from "../../apiClient/generated";
import { Card } from "../../ui/Card";
import {
    BarsArrowDownIcon,
    MagnifyingGlassIcon,
    ShieldCheckIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useDebounce } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import { ROUTES } from "..";
import { DataTable } from "../../components/InfrastructureDataTable/DataTable";

const columnHelper = createColumnHelper<AdminInfrastructureImport>();
const columns = [
    columnHelper.accessor("csvInputFilename", {
        header: () => "CSV Import File",
        cell: (info) => info.renderValue(),
        enableSorting: true,
    }),
    columnHelper.accessor("importStatus", {
        header: "Status",
        cell: (info) => (
            <i className="capitalize">
                {info.getValue().split("_").join(" ").toLowerCase()}
            </i>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor("ownerName", {
        header: () => "Owner",
        cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("itemCount", {
        header: () => "Number of items",
        cell: (info) => (info.renderValue() !== 0 ? info.renderValue() : "-"),
        enableSorting: false,
    }),
    columnHelper.accessor("createdByName", {
        header: () => "Created By",
        cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("updatedAt", {
        header: () => "Last Updated At",
        cell: (info) =>
            DateTime.fromJSDate(info.renderValue()).toLocaleString(
                DateTime.DATETIME_MED_WITH_SECONDS,
            ),
    }),
    columnHelper.display({
        id: "actions",
        header: () => "Actions",
        cell: (props) => {
            if (
                props.row.original.importStatus ===
                    ImportStatusEnum.WaitingImport ||
                props.row.original.importStatus ===
                    ImportStatusEnum.ImportingData
            ) {
                return "-";
            }

            return (
                <div className="flex items-center space-x-1">
                    {props.row.original.importStatus ===
                        ImportStatusEnum.Complete &&
                        props.row.original.csvConflictsFile && (
                            <button
                                title="Download blocked conflicts"
                                onClick={() => {
                                    window.open(
                                        props.row.original.csvConflictsFile,
                                        "_blank",
                                    );
                                }}
                                className="flex w-fit items-center bg-slate-300 p-2 rounded-md text-black hover:text-white hover:bg-slate-500"
                            >
                                <BarsArrowDownIcon className="w-4 h-4" />
                            </button>
                        )}
                    {props.row.original.importStatus !==
                        ImportStatusEnum.Complete && (
                        <button
                            onClick={() => {
                                if (
                                    confirm(
                                        "Are you sure you want to delete this import?",
                                    )
                                ) {
                                    props.table.options.meta?.deleteRow(
                                        props.row.index,
                                        props.row.original.id,
                                    );
                                }
                            }}
                            className="flex w-fit items-center bg-slate-300 p-2 rounded-md text-black hover:text-white hover:bg-slate-500"
                        >
                            <TrashIcon className="w-4 h-4" />
                        </button>
                    )}
                    {(props.row.original.importStatus === "WAITING_REVIEW" ||
                        props.row.original.importStatus ===
                            "VERIFY_AND_COMPLETE") && (
                        <Link
                            to={`${ROUTES.ADMIN_INFRASTRUCURE_IMPORT_APPROVAL_VIEW.replace(
                                ":id",
                                props.row.original.id,
                            )}`}
                            className="flex w-fit items-center bg-slate-300 p-2 rounded-md text-black hover:text-white hover:bg-slate-500"
                        >
                            <ShieldCheckIcon className="w-4 h-4" />
                        </Link>
                    )}
                </div>
            );
        },
    }),
];

export const AdminInfrastructureImportPage = () => {
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 400);

    const fetchFunction = async (props: any) => {
        return await apiClient.infrastructureAdminImportList(props);
    };

    const deleteFunction = async (itemId: string) => {
        return await apiClient.infrastructureImportDestroy({
            id: itemId,
        });
    };

    return (
        <div className="container mx-auto px-6">
            <h1 className="mt-6 text-2xl">Manage Infrastructure Imports</h1>
            <Card>
                <div className="mx-4 mb-3 flex items-center text-sm justify-between">
                    <div className="flex items-center">
                        <input
                            type="text"
                            className="rounded-lg py-1 px-2"
                            placeholder="Search by CSV filename"
                            value={filterSearch}
                            onChange={(e) => setFilterSearch(e.target.value)}
                        />
                        <MagnifyingGlassIcon className="w-5 h-5 -ml-7" />
                    </div>
                </div>
                <hr />
                <DataTable
                    dataName="infrastructureImport"
                    columns={columns}
                    extraFilters={{
                        search: debouncedSearch,
                    }}
                    fetchFunction={fetchFunction}
                    sortable={true}
                    deleteRow={deleteFunction}
                />
            </Card>
        </div>
    );
};
