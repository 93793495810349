/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataPointMapGeometry
 */
export interface DataPointMapGeometry {
    /**
     * 
     * @type {string}
     * @memberof DataPointMapGeometry
     */
    type?: DataPointMapGeometryTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof DataPointMapGeometry
     */
    coordinates?: Array<Array<Array<Array<number>>>>;
}


/**
 * @export
 */
export const DataPointMapGeometryTypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;
export type DataPointMapGeometryTypeEnum = typeof DataPointMapGeometryTypeEnum[keyof typeof DataPointMapGeometryTypeEnum];


/**
 * Check if a given object implements the DataPointMapGeometry interface.
 */
export function instanceOfDataPointMapGeometry(value: object): boolean {
    return true;
}

export function DataPointMapGeometryFromJSON(json: any): DataPointMapGeometry {
    return DataPointMapGeometryFromJSONTyped(json, false);
}

export function DataPointMapGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPointMapGeometry {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function DataPointMapGeometryToJSON(value?: DataPointMapGeometry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

