import { ControlButton, MapDropdownMenu } from "../../ui/MapControls";
import { MenuCategory } from "../../ui/Menu";
import { ServerStackIcon } from "@heroicons/react/24/outline";
import { basemaps } from "../../../Map/basemaps";
import { useMap } from "../../hooks/mapState";

export const BasemapControls = () => {
    const { basemap, setMapState } = useMap("mainMap");

    return (
        <MapDropdownMenu
            button={
                <ControlButton className="w-8">
                    <ServerStackIcon className="w-4" />
                </ControlButton>
            }
        >
            <div className="w-44">
                <MenuCategory>Basemaps</MenuCategory>
                <div className="text-sm flex flex-col gap-2 p-2 whitespace-nowrap">
                    <label className="flex gap-2 items-center">
                        <input
                            type="radio"
                            name="basemapSelector"
                            checked={basemap === null}
                            onChange={() =>
                                setMapState((ms) => ({ ...ms, basemap: null }))
                            }
                            className="text-ae-blue-500"
                        />
                        Default
                    </label>
                    {Object.entries(basemaps).map(([key, value]) => (
                        <div key={key} className="flex gap-2 items-center">
                            <input
                                id={key}
                                type="radio"
                                name="basemapSelector"
                                checked={basemap === key}
                                onChange={() =>
                                    setMapState((ms) => ({
                                        ...ms,
                                        basemap: key,
                                    }))
                                }
                                className="text-ae-blue-500"
                            />
                            <label htmlFor={key}>{value.displayName}</label>
                        </div>
                    ))}
                </div>
            </div>
        </MapDropdownMenu>
    );
};
