import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/sharp-regular-svg-icons";

type Values = "yes" | "no" | "partial";

const stateMap: { [key in Values]: Values } = {
    yes: "no",
    partial: "no",
    no: "yes",
};

interface CustomCheckboxProps {
    checked: Values;
    onClick: (value: Values) => void;
    disabled?: boolean;
}

export const CustomCheckbox = (props: CustomCheckboxProps) => (
    <button
        className={`
            rounded border-2  h-4 w-4
            flex items-center justify-center text-white
            ${
                props.disabled
                    ? "bg-ae-gray-100 border-ae-gray-250"
                    : props.checked === "yes"
                      ? "bg-ae-blue-550 border-ae-blue-550"
                      : "border-ae-gray-250"
            }
        `}
        onClick={() => {
            if (!props.disabled) {
                props.onClick(stateMap[props.checked]);
            }
        }}
    >
        {!props.disabled && (
            <>
                {props.checked === "yes" && (
                    <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />
                )}
                {props.checked === "partial" && (
                    <div className="w-2 h-2 bg-ae-blue-550" />
                )}
            </>
        )}
    </button>
);
