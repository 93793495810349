import {
    ArrowPathIcon,
    ArrowsUpDownIcon,
    CheckCircleIcon,
    CheckIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    TableCellsIcon,
    XCircleIcon,
} from "@heroicons/react/24/solid";
import { InfraTypeEnum, InfrastructureImport } from "../../apiClient/generated";
import { Card } from "../../ui/Card";
import { Tab } from "@headlessui/react";
import { RelationshipBuilderGraphView } from "./RelationshipBuilderGraphView";
import { TableView } from "./TableView";
import { useEffect, useState } from "react";
import { useInfrastructureApiClient } from "../../hooks";
import { DetailView, DetailViewActions } from "../../ui/DetailView";
import { GeoJSONInfrastructurePreviewMiniMap } from "../Map/MiniMap";
import { Cog6ToothIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

interface ConfirmAndSubmitModalProps {
    visible: boolean;
    importDataId?: string;
    onClose: () => void;
    onSave: () => void;
}

const ConfirmAndSubmitModal = (props: ConfirmAndSubmitModalProps) => {
    const apiClient = useInfrastructureApiClient();

    // Flow control
    const [geoJson, setGeoJson] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [loadingValidation, setLoadingValidation] = useState(true);
    const [validationMessages, setValidationMessages] = useState<
        {
            pass: boolean;
            warning: boolean;
            message: string;
        }[]
    >([]);

    // Every time modal is reloaded, reload map data.
    useEffect(() => {
        const fetchPreviewData = async () => {
            setLoading(true);
            // Load map preview data here
            const response =
                await apiClient.infrastructureImportGetGeojsonRetrieve({
                    id: props.importDataId,
                });
            setGeoJson(response);
            setLoading(false);
        };
        const validateRequirements = async () => {
            setLoadingValidation(true);
            const valMessages = [];

            // Check if all items have IDs and no locations are missing
            const idResponse = await apiClient.infrastructureImportItemsList({
                relatedImport: props.importDataId,
                emptyPlaceholderId: true,
            });

            if (idResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    warning: false,
                    message: `No ID is set for ${idResponse.count} infrastructure points`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All infrastructure points have IDs.",
                });
            }

            // Items missing location
            const noLocationResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.importDataId,
                    noLocation: true,
                });

            if (noLocationResponse.count > 0) {
                // Check if any sites are missing locations
                const noLocationSiteResponse =
                    await apiClient.infrastructureImportItemsList({
                        relatedImport: props.importDataId,
                        noLocation: true,
                        infraType: InfraTypeEnum.Site as string,
                    });

                if (noLocationSiteResponse.count > 0) {
                    valMessages.push({
                        pass: false,
                        warning: false,
                        message: `No location information in ${noLocationSiteResponse.count} site(s).`,
                    });
                } else {
                    valMessages.push({
                        pass: true,
                        warning: true,
                        message: `No location information in ${noLocationResponse.count} rows`,
                    });
                }
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All points have information location.",
                });
            }

            // Missing parents
            const missingParentResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.importDataId,
                    missingParent: true,
                });

            if (missingParentResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    warning: false,
                    message: `Missing parent relationships in ${missingParentResponse.count} rows`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "Relationship set for all infrastructures.",
                });
            }

            // Items missing infra type
            const missingInfraTypeResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.importDataId,
                    missingInfraType: true,
                });
            if (missingInfraTypeResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    message: `No infrastructure type defined in ${missingInfraTypeResponse.count} rows`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All points have infrastructure type information.",
                });
            }

            // Items missing infra type
            const missingLocationMatchResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.importDataId,
                    locationNeedsReview: true,
                });
            if (missingLocationMatchResponse.count > 0) {
                valMessages.push({
                    pass: false,
                    message: `${missingLocationMatchResponse.count} infrastructure match(es) not reviewed.`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All matched infrastructure was reviewed.",
                });
            }

            // Items with duplicated
            const hasDuplicatesResponse =
                await apiClient.infrastructureImportItemsList({
                    relatedImport: props.importDataId,
                    hasLineDuplicates: true,
                });
            if (hasDuplicatesResponse.count > 0) {
                valMessages.push({
                    pass: true,
                    warning: true,
                    message: `${hasDuplicatesResponse.count} possible row duplicates unreviewed.`,
                });
            } else {
                valMessages.push({
                    pass: true,
                    warning: false,
                    message: "All possible duplicates row conflicts resolved.",
                });
            }

            setValidationMessages(valMessages);
            setLoadingValidation(false);
        };
        if (props.visible) {
            fetchPreviewData();
            validateRequirements();
        }
    }, [props.visible, props.importDataId, apiClient]);

    return (
        <DetailView
            title="Preview data and submit"
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="flex">
                <div className="w-1/3">
                    {loadingValidation ? (
                        <div className="flex justify-center">
                            <ArrowPathIcon className="animate-spin w-10 h-10" />
                        </div>
                    ) : (
                        <div>
                            <p className="font-bold">Data checks:</p>
                            {validationMessages.map((s, index) => (
                                <p
                                    key={`message_${index}`}
                                    className="flex items-center"
                                >
                                    {s.pass ? (
                                        s.warning ? (
                                            <ExclamationCircleIcon className="h-5 w-5 mr-2 text-yellow-500" />
                                        ) : (
                                            <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" />
                                        )
                                    ) : (
                                        <XCircleIcon className="h-5 w-5 mr-2 text-red-500" />
                                    )}
                                    {s.message}
                                </p>
                            ))}
                            <p className="mt-4 text-sm">
                                Use the map preview to double check how the
                                infrastructure will be displayed on the
                                platform.
                            </p>
                        </div>
                    )}
                </div>
                <div className="relative w-2/3 h-[500px] overflow-hidden">
                    <GeoJSONInfrastructurePreviewMiniMap geojson={geoJson} />
                    {loading && (
                        <div className="relative w-full h-full flex justify-center flex-col m-auto">
                            <div className="p-4 bg-white bg-opacity-40">
                                <div className="flex justify-center">
                                    <ArrowPathIcon className="w-8 h-8 animate-spin mb-2" />
                                </div>
                                <div className="flex justify-center">
                                    Loading preview...
                                </div>
                                <small className="flex justify-center">
                                    You don't need to wait for this to load to
                                    continue.
                                </small>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <DetailViewActions
                actions={[
                    {
                        action: props.onClose,
                        label: "Go back",
                    },
                    {
                        action: props.onSave,
                        label: "Submit data and go to next step",
                        disabled:
                            loadingValidation ||
                            validationMessages.some((i) => !i.pass),
                    },
                ]}
            />
        </DetailView>
    );
};

interface LocationMatcherUpdateModalProps {
    visible: boolean;
    importData: InfrastructureImport;
    onClose: () => void;
    onSave: (exactMatchDistance: number, nearMatchDistance: number) => void;
}

const LocationMatcherUpdateModal = (props: LocationMatcherUpdateModalProps) => {
    const [exactMatchDistance, setExactMatchDistance] = useState(
        props.importData.exactMatchDistance,
    );
    const [nearMatchDistance, setNearMatchDistance] = useState(
        props.importData.nearMatchDistance,
    );

    return (
        <DetailView
            title="Location matcher configuration"
            visible={props.visible}
            onClose={props.onClose}
        >
            <div>
                <table>
                    <tr>
                        <td>
                            <label>Exact Match distance (in meters):</label>
                        </td>
                        <td>
                            <input
                                type="number"
                                className="ml-2 rounded my-2 px-2 py-1 w-48"
                                value={exactMatchDistance}
                                onChange={(e) =>
                                    setExactMatchDistance(
                                        parseInt(e.target.value),
                                    )
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Near Match distance (in meters):</label>
                        </td>
                        <td>
                            <input
                                type="number"
                                className="ml-2 rounded my-2 px-2 py-1 w-48"
                                value={nearMatchDistance}
                                onChange={(e) =>
                                    setNearMatchDistance(
                                        parseInt(e.target.value),
                                    )
                                }
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <DetailViewActions
                actions={[
                    {
                        action: props.onClose,
                        label: "Go back",
                    },
                    {
                        action: () => {
                            props.onSave(exactMatchDistance, nearMatchDistance);
                        },
                        label: "Change tolerances and re-run",
                    },
                ]}
            />
        </DetailView>
    );
};

interface LineDeduplicationModalProps {
    visible: boolean;
    importData: InfrastructureImport;
    onClose: () => void;
    onSave: (centroidDistance: number) => void;
}

const LineDeduplicationModal = (props: LineDeduplicationModalProps) => {
    const [centroidDistance, setCentroidDistance] = useState(
        props.importData.similarityCheckerCentroidDistance,
    );

    return (
        <DetailView
            title="Line deduplication configuration"
            visible={props.visible}
            onClose={props.onClose}
        >
            <div>
                <table>
                    <tr>
                        <td>
                            <label>Exact Match distance (in meters):</label>
                        </td>
                        <td>
                            <input
                                type="number"
                                className="ml-2 rounded my-2 px-2 py-1 w-48"
                                value={centroidDistance}
                                onChange={(e) =>
                                    setCentroidDistance(
                                        parseInt(e.target.value),
                                    )
                                }
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <DetailViewActions
                actions={[
                    {
                        action: props.onClose,
                        label: "Go back",
                    },
                    {
                        action: () => {
                            props.onSave(centroidDistance);
                        },
                        label: "Change parameters and re-run",
                    },
                ]}
            />
        </DetailView>
    );
};

interface VerifyAndPreviewProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const VerifyAndPreview = (props: VerifyAndPreviewProps) => {
    const apiClient = useInfrastructureApiClient();
    const [modalOpen, setModalOpen] = useState(false);
    const [showLocationMatchModal, setShowLocationMatchModal] = useState(false);
    const [showLineDeduplicationModal, setShowLineDeduplicationModal] =
        useState(false);

    // Finishing step
    const submitData = async () => {
        await apiClient.infrastructureImportValidateDataAndSubmitCreate({
            id: props.importData.id,
        });
        props.refresh();
    };

    return (
        <>
            <Tab.Group>
                <Card>
                    <h2>Verify & Complete data</h2>
                    <hr className="my-2" />
                    <p className="text-base mb-2 ">
                        This step allows you to preview and correct any
                        information from the processed CSV file. When you are
                        ready to continue the import process, check on the
                        button below to preview the map and run data checks.
                    </p>
                    {Array.isArray(props.importData.errors) &&
                        props.importData.errors.length > 0 && (
                            <div className="text-base my-2 rounded-lg">
                                <div>
                                    <p className="flex items-center ">
                                        Some errors were found in the initial
                                        data import:
                                    </p>
                                    <ul className="list-inside max-h-20 overflow-y-scroll">
                                        {props.importData.errors.map((err) => (
                                            <li
                                                title={err}
                                                className="flex items-center"
                                            >
                                                <ExclamationTriangleIcon className="h-4 w-4 mr-2 text-yellow-600" />
                                                <span className="truncate max-w-[600px]">
                                                    {err}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    <div className="flex justify-between text-base">
                        <Tab.List className="flex space-x-2 transition-all">
                            <Tab
                                className={({ selected }) =>
                                    `flex items-center py-2 px-4 justify-center rounded-lg hover:bg-slate-200 transition-all ${
                                        selected
                                            ? "text-green-600 bg-slate-200"
                                            : "text-slate-600"
                                    }`
                                }
                            >
                                <TableCellsIcon className="mr-2 w-6 h-6" />
                                Table
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    `flex items-center py-2 px-4 justify-center rounded-lg hover:bg-slate-200 transition-all ${
                                        selected
                                            ? "text-green-600 bg-slate-200"
                                            : "text-slate-600"
                                    }`
                                }
                            >
                                <ArrowsUpDownIcon className="mr-2 w-6 h-6" />
                                Graph view
                            </Tab>
                        </Tab.List>
                        <div className="flex gap-2">
                            <button
                                onClick={() => setShowLocationMatchModal(true)}
                                className="flex px-4 py-2 items-center rounded-lg bg-slate-100 hover:bg-slate-400 hover:text-white"
                            >
                                <Cog6ToothIcon className="w-6 h-6 mr-2" />
                                Configure location matcher
                            </button>
                            <button
                                onClick={() =>
                                    setShowLineDeduplicationModal(true)
                                }
                                className="flex px-4 py-2 items-center rounded-lg bg-slate-100 hover:bg-slate-400 hover:text-white"
                            >
                                <DocumentTextIcon className="w-6 h-6 mr-2" />
                                Configure line deduplication
                            </button>
                            <button
                                onClick={() => setModalOpen(true)}
                                className="flex px-4 py-2 items-center rounded-lg bg-slate-200 hover:bg-slate-600 hover:text-white"
                            >
                                <CheckIcon className="w-6 h-6 mr-2" />
                                Preview data & Submit
                            </button>
                        </div>
                    </div>
                </Card>
                <Tab.Panels>
                    <Tab.Panel>
                        <Card>
                            <TableView importData={props.importData} />
                        </Card>
                    </Tab.Panel>
                    <Tab.Panel>
                        <Card>
                            <RelationshipBuilderGraphView
                                importData={props.importData}
                            />
                        </Card>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            <ConfirmAndSubmitModal
                visible={modalOpen}
                importDataId={props.importData.id}
                onClose={() => setModalOpen(false)}
                onSave={submitData}
            />
            <LocationMatcherUpdateModal
                visible={showLocationMatchModal}
                importData={props.importData}
                onClose={() => setShowLocationMatchModal(false)}
                onSave={async (exactMatchDistance, nearMatchDistance) => {
                    await apiClient.infrastructureImportPartialUpdate({
                        id: props.importData.id,
                        patchedInfrastructureImportRequest: {
                            exactMatchDistance,
                            nearMatchDistance,
                        },
                    });
                    await apiClient.infrastructureImportRerunLocationMatcherCreate(
                        {
                            id: props.importData.id,
                        },
                    );
                    props.refresh();
                }}
            />
            <LineDeduplicationModal
                visible={showLineDeduplicationModal}
                importData={props.importData}
                onClose={() => setShowLineDeduplicationModal(false)}
                onSave={async (similarityCheckerCentroidDistance) => {
                    await apiClient.infrastructureImportPartialUpdate({
                        id: props.importData.id,
                        patchedInfrastructureImportRequest: {
                            similarityCheckerCentroidDistance,
                        },
                    });
                    await apiClient.infrastructureImportRerunLineDeduplicationMatcherCreate(
                        {
                            id: props.importData.id,
                        },
                    );
                    props.refresh();
                }}
            />
        </>
    );
};
