import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import {
    DataPointMap,
    EmissionRecordMap,
    EmissionRecordMapGeometry,
    PlumeImage,
} from "../../../apiClient/generated";
import { BitmapLayer } from "@deck.gl/layers";
import { MAP_ZOOM_SHOW_DETAILS } from "../constants";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import type { FeatureCollection } from "geojson";

const iconMapping = {
    marker: {
        x: 0,
        y: 0,
        width: 48,
        height: 48,
        mask: false,
    },
};

export const EmissionRecordsLayer = (
    data: EmissionRecordMap[] | DataPointMap[],
    visible: boolean,
    zoomLevel: number,
    color: [number, number, number],
    iconAtlas: string,
    layerId: string = "emissions",
    onHover?: (hoverInfo: any) => void,
) => {
    if (zoomLevel > MAP_ZOOM_SHOW_DETAILS) {
        return [
            new IconLayer({
                id: `${layerId}_crosshairs`,
                data: createFeatureCollection(data).features,
                iconAtlas,
                iconMapping,
                getIcon: () => "marker",
                getPosition: (d) => d.geometry.coordinates,
                getSize: 25,
                getColor: () => color,
                alphaCutoff: 0.05,
                highlightColor: [255, 255, 255, 128],
                stroked: true,
                pickable: true,
                visible,
                autoHighlight: true,
                onHover,
            }),
        ];
    }

    return [
        new GeoJsonLayer({
            id: layerId,
            data: createFeatureCollection(data),
            pointType: "circle",
            filled: true,
            getPointRadius: (i) => {
                if (zoomLevel > MAP_ZOOM_SHOW_DETAILS) {
                    return 6;
                }
                return i.properties.mapDotSize / 2;
            },
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: true,
            visible,
            autoHighlight: true,
            onHover,
            highlightColor: [0, 0, 0, 30],
            getFillColor: () => {
                if (zoomLevel > MAP_ZOOM_SHOW_DETAILS) {
                    return [...color, 255];
                }
                return [...color, 100];
            },
            getLineWidth: 2,
            getLineColor: () => {
                if (zoomLevel > MAP_ZOOM_SHOW_DETAILS) {
                    return [0, 0, 0, 255];
                }
                return [...color, 255];
            },
        }),
    ];
};

/**
 * FIXME: This is too similar to the aerial images layer, merge them
 * and deduplicate the code.
 */
export const EmissionImagesLayer = (
    data: PlumeImage[],
    name: string = "plumes",
    visible: boolean,
    zoomLevel: number,
    opacity: number,
) => {
    if (!data) {
        return [];
    }

    if (zoomLevel < 12) {
        return [];
    }

    // Filter images inside map view and return image array
    return data.map((item) => {
        return new BitmapLayer({
            id: `${name}_${item.id}`,
            // FIXME: improve serializer definition of bounds
            bounds: item.bounds as any,
            image: item.image,
            visible: visible,
            opacity: opacity,
        });
    });
};

export const PlumeOutlinesLayer = (
    data: EmissionRecordMapGeometry[],
    name: string = "plume_outlines",
    visible: boolean,
    zoomLevel: number,
    opacity: number,
) => {
    if (!data) {
        return [];
    }

    if (zoomLevel < 12) {
        return [];
    }

    const featureCollection: FeatureCollection = {
        type: "FeatureCollection",
        features: data.map((outline) => {
            return {
                type: "Feature",
                geometry: outline,
                properties: {},
            };
        }) as unknown as any,
    };

    const color: [number, number, number] = [23, 122, 212];

    return new GeoJsonLayer({
        id: name,
        data: featureCollection,
        pointType: "line",
        filled: true,
        stroked: true,
        pickable: false,
        visible: visible,
        getLineWidth: 3,
        getLineColor: [...color, opacity > 0 ? 255 : 0],
        getFillColor: [...color, opacity * 255],
    });
};
