import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ArrowDownTrayIcon,
    BuildingOffice2Icon,
    ArrowUpTrayIcon,
    MapIcon,
    PuzzlePieceIcon,
    Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { ComponentType, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useAppSelector } from "../../hooks";
import aershedLogo from "./../../assets/img/aershed-logo-horizontal.png";
import aershedLogoSmall from "./../../assets/img/aershed-symbol.png";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/solid";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { UnifiedIcon } from "../UnifiedIcon";

import {
    faBuildingColumns,
    faBullseyeArrow,
} from "@fortawesome/pro-light-svg-icons";

interface SidebarMenuProps {
    icon: ComponentType<any> | IconDefinition;
    to: string;
    text: string;
    expanded: boolean;
}

const SidebarButton = (props: SidebarMenuProps) => {
    return (
        <NavLink
            to={props.to}
            className={({ isActive }) => {
                return `
                    px-4 py-3 w-full rounded-lg flex items-center justify-center
                    transition-all gap-4 ${
                        isActive
                            ? "bg-ae-blue-900 text-stone-50 font-bold"
                            : "text-ae-blue-900 hover:bg-neutral-200"
                    }
                `;
            }}
        >
            <UnifiedIcon icon={props.icon} className="h-5 w-5" />
            <h2
                className={`flex-grow font-medium ${
                    props.expanded ? "" : "hidden"
                }`}
            >
                {props.text}
            </h2>
        </NavLink>
    );
};

export const SideMenu = () => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(true);

    // User specific information
    const isStaff = useAppSelector((state) => state.auth.isStaff);
    const firstName = useAppSelector((state) => state.auth.firstName);
    const lastName = useAppSelector((state) => state.auth.lastName);

    // Permissions
    const isSuperuser = useAppSelector((state) => state.auth.isSuperuser);
    const userPermissions = useAppSelector((state) => state.auth.permissions);
    const flags = useAppSelector((state) => state.auth.flags);

    return (
        <div
            className={`
                ${expanded ? "w-64" : "w-20"} h-screen
                bg-ae-neutral-50 border-r border-slate-200
                flex flex-col p-3 transition-all group relative
                overflow-y-scroll overflow-x-hidden
            `}
        >
            {/* Expand/collapse controls */}
            <button
                title={expanded ? "Minimize menu" : "Expand menu"}
                className="hidden group-hover:block absolute -right-0.5 rounded-l-lg top-2/3 -translate-y-6 p-2 hover:bg-ae-neutral-200"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? (
                    <ChevronDoubleLeftIcon className="w-5" />
                ) : (
                    <ChevronDoubleRightIcon className="w-5" />
                )}
            </button>

            {/* Header */}
            <div className="mt-4 mb-14 flex items-center justify-evenly">
                <a
                    onClick={() => {
                        navigate(ROUTES.ROOT);
                    }}
                >
                    <img
                        className="max-h-10"
                        src={expanded ? aershedLogo : aershedLogoSmall}
                    />
                </a>
            </div>

            {/* Main navigation pages */}
            <div className="flex-grow flex flex-col gap-1 mb-1">
                {/* Dashboards */}
                {flags.includes("enable_unified_dashboards") && (
                    <SidebarButton
                        to={ROUTES.DASHBOARD}
                        icon={faBullseyeArrow}
                        text="Emissions"
                        expanded={expanded}
                    />
                )}
                {/* Map */}
                {userPermissions.mapView && (
                    <SidebarButton
                        to={ROUTES.MAP}
                        icon={MapIcon}
                        text="Maps"
                        expanded={expanded}
                    />
                )}
                {userPermissions.infrastructureView && (
                    <SidebarButton
                        to={ROUTES.INFRASTRUCURE}
                        icon={BuildingOffice2Icon}
                        text="Infrastructure"
                        expanded={expanded}
                    />
                )}
                {userPermissions.dataUploadsView && (
                    <SidebarButton
                        to={ROUTES.DATAUPLOADS}
                        icon={ArrowUpTrayIcon}
                        text="Data upload"
                        expanded={expanded}
                    />
                )}
                {userPermissions.dataDownloadView && (
                    <SidebarButton
                        to={ROUTES.DATADOWNLOAD}
                        icon={ArrowDownTrayIcon}
                        text="Data download"
                        expanded={expanded}
                    />
                )}
            </div>

            {/* Items attached to bottom of page */}
            <div>
                <div className="flex flex-col mb-8 gap-1">
                    {isStaff && (
                        <SidebarButton
                            to={ROUTES.ADMIN_DASHBOARD}
                            icon={PuzzlePieceIcon}
                            text="Administration"
                            expanded={expanded}
                        />
                    )}
                    <SidebarButton
                        to={ROUTES.SUPPORT}
                        icon={QuestionMarkCircleIcon}
                        text="Help & Support"
                        expanded={expanded}
                    />
                    <SidebarButton
                        to={ROUTES.SETTINGS_HOME}
                        icon={Cog6ToothIcon}
                        text="Settings"
                        expanded={expanded}
                    />
                    <SidebarButton
                        to={ROUTES.LICENSING}
                        icon={faBuildingColumns}
                        text="Licensing"
                        expanded={expanded}
                    />
                </div>

                {/* User header */}
                <div className="px-4 mb-4 flex justify-evenly items-center text-ae-blue-900">
                    {expanded && (
                        <div className="grow text-base font-bold">
                            {`${firstName} ${lastName}`}
                        </div>
                    )}
                    <NavLink
                        title="Logout"
                        to={ROUTES.LOGOUT}
                        className="flex items-center justify-center p-2 rounded-lg hover:bg-neutral-200 "
                    >
                        <ArrowRightEndOnRectangleIcon className="h-5" />
                    </NavLink>
                </div>

                {/* Superuser account warning */}
                {isStaff && isSuperuser && (
                    <p className="flex bg-red-500 justify-center p-2 -mb-3 -mx-3 text-white text-sm font-bold">
                        {expanded ? (
                            "You are using a superuser account!"
                        ) : (
                            <ExclamationTriangleIcon className="h-5" />
                        )}
                    </p>
                )}
            </div>
        </div>
    );
};
